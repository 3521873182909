import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs';
import { UsersService } from 'src/app/services/users/users.service';

import { getOrgSlug } from '../../guards/valid-org.guard';
import { Organization } from '../../models/organization';
import { orgRoleNameMap } from '../../models/users.types';
import { getOrgInitials, orgTitles, stringToAvatarColor } from '../../services/organizations/organizations.service';
import { SelfService } from '../../services/self/self.service';

@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.scss'],
})

export class OnboardComponent implements OnInit {
  onboardForm: FormGroup;
  org?: Organization;
  updateSelf;
  updateOrgUserDetails;

  constructor(
    private selfService: SelfService,
    private usersService: UsersService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
  ) {
    this.updateOrgUserDetails = usersService.injectOrgUserDetailsUpdateMutation({
      onSettled: async () => {
        if (this.org) {
          const slug = getOrgSlug(this.org);
          await router.navigate(['org', slug, 'dashboard']);
        }
      },
    });
    this.updateSelf = selfService.injectUpdateSelfMutation({
      onSuccess: () => {
        if (this.org) {
          this.updateOrgUserDetails.mutate({
            org_id: this.org.organization_id,
            job_titles: this.onboardForm.value.jobTitles || [],
          });
        }
      },
      onError: async () => {
        if (this.org) {
          const slug = getOrgSlug(this.org);
          await router.navigate(['org', slug, 'dashboard']);
        }
      },
    });
    this.onboardForm = this.fb.group({
      firstName: this.fb.control('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(64),
      ]),
      lastName: this.fb.control('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(64),
      ]),
      jobTitles: this.fb.control('', [
        Validators.required,
        Validators.minLength(1),
      ]),
    });
  }

  ngOnInit(): void {
    this.verifyInvite();
  }

  verifyInvite(): void {
    this.activatedRoute.queryParams.pipe(
      switchMap((qParams) =>
        this.usersService.verifyInvite(qParams['org_id'], qParams['code']),
      ),
    ).subscribe({
      next: (userResponse) => {
        if (!userResponse.body) {
          this.router.navigate(['invite-failure']);
        } else {
          switch (userResponse.status) {
            case 200: // Success
              this.onboardForm.patchValue({
                firstName: userResponse.body.given_name,
                lastName: userResponse.body.family_name,
              });
              this.org = { ...userResponse.body.organization, organization_id: userResponse.body.organization_id };
              break;
            case 208: // Already Reported (return trip from link)
              this.router.navigate(['org', getOrgSlug(userResponse.body.organization), 'dashboard']);
              break;
            default:
              this.router.navigate(['invite-failure']);
              break;
          }
        }
      },
      error: () => {
        this.router.navigate(['invite-failure']);
      },
    });
  }

  getErrorMessage(controlName: 'firstName' | 'lastName'): string {
    const errors = this.onboardForm.controls[controlName].errors;

    const controlNameToLabelMap = {
      'firstName': 'First name',
      'lastName': 'Last name',
    };

    if (errors?.['required']) {
      const controlLabel = controlNameToLabelMap[controlName];
      return `${controlLabel} is required`;
    }
    if (errors?.['maxlength']) {
      return 'Maximum length is 64 characters';
    }
    if (errors?.['minlength']) {
      return 'Minimum length is 2 characters';
    }

    return '';
  }

  submitForm(): void {
    if (this.onboardForm.valid) {
      this.updateSelf.mutate({
        family_name: this.onboardForm.value.lastName || '',
        given_name: this.onboardForm.value.firstName || '',
      });
    }
  }

  protected readonly stringToAvatarColor = stringToAvatarColor;
  protected readonly roleNameMap = orgRoleNameMap;
  protected readonly getOrgInitials = getOrgInitials;
  protected readonly orgTitles = orgTitles;
}
