<app-theme [fitted]="true">
  <fw-layout-context
    title="404 Error"
    description="This page you are looking for does not exist."
    iconColor="danger"
    icon="search-loop"
  >
    <fw-button
      variant="outline"
      [fullWidth]="true"
      (click)="returnToSite()"
      [testId]="'btn-site'">
      Return to Home
    </fw-button>
  </fw-layout-context>
</app-theme>
