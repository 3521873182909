import { NgModule } from '@angular/core';

import { OrganizationsModule } from './organizations/organizations.module';
import { TenantsModule } from './tenants/tenants.module';

@NgModule({
  imports: [
    OrganizationsModule,
    TenantsModule,
  ],
  declarations: [],
  exports: [],
})
export class AdminModule {
}
