import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { FwSnackbarService } from '@flywheel-io/vision';

import { Tenant } from '../../../models/tenant';
import { OrganizationsService } from '../../../services/organizations/organizations.service';

@Component({
  selector: 'app-deactivate-tenant-dialog',
  templateUrl: './deactivate-tenant-dialog.component.html',
  styleUrls: ['./deactivate-tenant-dialog.component.scss'],
})
export class DeactivateTenantDialogComponent {
  updateOrgTenantMutation;
  errorMessage?: string;
  busy: boolean = false;
  tenantPath: string = '';

  constructor(
    private orgServices: OrganizationsService,
    public snackbarService: FwSnackbarService,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data?: Tenant,
  ) {
    this.updateOrgTenantMutation = this.orgServices.injectUpdateOrgTenantMutation({
      onSuccess: () => {
        this.snackbarService.show({
          severity: 'success',
          message: 'Tenant deactivated!',
        });
        this.busy = false;
        this.dialogRef.close();
      },
      onError: (err) => {
        this.snackbarService.show({
          severity: 'error',
          message: 'Something went wrong during deactivation.',
        });
        this.errorMessage = `${err.error.detail}`;
        this.busy = false;
      },
    });

  }

  cancel(): void {
    this.dialogRef.close();
  }

  deactivateTenant(): void {
    if(this.isDeactivationAllowed() && this.data) {
      this.busy = true;
      this.updateOrgTenantMutation.mutate({
        tenant_id: this.data.id,
        org_id: this.data.organization_id,
        deployment_status: 'deactivated',
      });
    }
  }

  handleTenantPathChange(event: Event): void {
    if (event.target) {
      const tenantInput = event.target as HTMLInputElement;
      this.tenantPath = tenantInput.value;
    }
  }

  isDeactivationAllowed(): boolean {
    return this.tenantPath.toLowerCase() === this.data?.path.toLowerCase();
  }
}
