import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-word-mark',
  templateUrl: './wordmark.component.html',
  styleUrls: ['./wordmark.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WordmarkComponent {

}
