import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isAfter, isBefore, isSameDay, parseISO } from 'date-fns';

const isFormControl = (obj: unknown | AbstractControl): obj is AbstractControl => {
  return Boolean((obj as AbstractControl)?.status);
}

export const notBeforeValidator = (earliestDateArg: AbstractControl | Date): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {

    // don't error empties as that's what required is for
    if(!control.value) {
      return null;
    }

    const valueToCheck = parseISO(control.value);
    const earliestDate = isFormControl(earliestDateArg) ? parseISO(earliestDateArg.value as string) : earliestDateArg;

    const isInvalid = isBefore(valueToCheck, earliestDate);
    const areSameDay = isSameDay(valueToCheck, earliestDate);

    const error = {
      dateRange: 'date range is invalid',
    }

    return (isInvalid && !areSameDay) ? error : null;
  }
}

export const isNotFutureValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {

    // don't error empties as that's what required is for
    if(!control.value) {
      return null;
    }

    // assume the control uses an ISO timestamp
    const valueToCheck = parseISO(control.value);

    const today = new Date();
    const isFuture = isAfter(valueToCheck, today);

    return isFuture ? { isFuture: true } : null;
  }
}


