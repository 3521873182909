<fw-dialog
  width="small"
  [showClose]="true"
  icon="data-tree"
  iconColor="red"
  [title]="'Deactivate Tenant'">

  <fw-dialog-content style="overflow-x: auto">
    <fw-layout-context
      width="medium"
      title="Are you sure you want to deactivate this tenant?"
      [description]="'This action cannot be undone. '+data?.path+' will become no longer be available.'"
      icon="warning-circle"
      iconColor="danger"
    >
    </fw-layout-context>
    <fw-layout-panel [margin]="true" [padded]="true">
      <fw-text-input
        [value]="tenantPath"
        (input)="handleTenantPathChange($event)"
        placeholder="Enter the Tenant Path ID here to continue."
      >
      </fw-text-input>
    </fw-layout-panel>
  </fw-dialog-content>
  <fw-dialog-actions>
    <div class="flex"></div>
    <fw-button
      variant="outline"
      (click)="cancel()"
      [testId]="'btn-edit-tenant-cancel'">
      Cancel
    </fw-button>
    <fw-button
      (click)="deactivateTenant()"
      [testId]="'btn-deactivate-tenant-send'"
      color="danger"
      [disabled]="busy || !isDeactivationAllowed()">
      Deactivate Tenant
    </fw-button>
  </fw-dialog-actions>
</fw-dialog>
