<app-theme page="shaded" [fitted]="true">
  <div *ngIf="displayDialog" class="dialog vision-shadow-large">
    <app-word-mark></app-word-mark>
    <h1 class="vision-h1">My Organizations</h1>
    <div class="org-list">
      <fw-contained-input
        *ngFor="let org of activeOrgsSignal()"
        (click)="navigateToOrg(org)"
        inputVariant="none"
        [title]="org.name"
        [description]="roleNameMap[org.organization_role]">
        <fw-avatar
          [color]="stringToAvatarColor(org.name)"
          size="medium" variant="rounded" content="initial"
          [initial]="getOrgInitials(org) || 'ab'">
        </fw-avatar>
      </fw-contained-input>
    </div>
    <p>Organization not listed? Speak with your Org Admin to make sure you have an account set up with them. </p>
    <fw-button rightIcon="logout" variant="ghost" (click)="logout()">Logout of Flywheel</fw-button>
  </div>
</app-theme>
