<div class="table-wrapper" fwMenuCloseTriggers>
  <app-organizations-table-header (refresh)="refresh()" (searchChanged)="handleSearchChanged($event)"></app-organizations-table-header>
  <mat-progress-bar mode="query" *ngIf="orgQuery && (orgQuery.isLoading() || orgQuery.isRefetching())"></mat-progress-bar>
  <div class="table-pane">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="name"
      matSortDirection="asc"
      #dsTbSort="matSort"
      class="organizations-table">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header [testId]="'table-header-name'">
          <h4>Name</h4>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="no-wrap">
          <fw-icon size="small" color="primary">user-profile-group-discovery</fw-icon>
          &nbsp;
          <h4>{{ row.name }}</h4>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="label">
        <mat-header-cell *matHeaderCellDef mat-sort-header [testId]="'table-header-label'">
          <h4>Label</h4>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="no-wrap flex-cell">
          <p>{{ row.label }}</p>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tenant_count">
        <mat-header-cell class="column-tenant-count" *matHeaderCellDef mat-sort-header [testId]="'table-header-tenants'">
          <h4>Tenants</h4>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="column-tenant-count">
          <p>{{row.tenant_count}}</p>
        </mat-cell>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns; index as i"
        [testId]="'table-row-'+i"
        (click)="rowSelected(row)">
      </tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell empty-pane" [attr.colspan]="displayedColumns.length">
          <fw-layout-context *ngIf="!orgQuery || !orgQuery.isFetching() || !orgQuery.isLoading()"
            icon="settings-gear-square"
            iconColor="secondary"
            title="No organizations found"
            description="Looks like there are not any organizations defined.">
          </fw-layout-context>
        </td>
      </tr>
    </table>

  </div>

  <div class="table-footer">
    <fw-paginator-advanced
      *ngIf="paginatedData"
      alignment="end"
      [length]="total"
      [pageIndex]="currentPageIndex"
      [pageSize]="pageSize"
      [disabled]="orgQuery.isFetching() || orgQuery.isLoading()"
      (page)="handlePageEvent($event)">
    </fw-paginator-advanced>
  </div>
</div>
