<app-theme [wrapped]="true">
  <div class="page-wrapper">
    <fw-section-heading
    title="Billing"
    [backButton]="true"
    >
      <fw-button leftIcon="document-file-download" variant="outline" [disabled]="true">Export</fw-button>
    </fw-section-heading>
    <app-billing-filters (filterChange)="onFilterChange($event)"></app-billing-filters>
    <div class="content-wrapper">
      <div *ngIf="billingQuery.isLoading()" id="loading-overlay">
        <fw-progress-spinner></fw-progress-spinner>
      </div>
      <section class="overall">
        <h1>{{formatDate(filters().range.start, "MMMM d, yyyy")}} - {{formatDate(filters().range.end, "MMMM d, yyyy")}}</h1>
        <div class="overall-info">
          <app-cost-card
            [highlighted]="true"
            title="Total Cost"
            icon="invoice"
            [cost]="totalCost()"
          ></app-cost-card>
          <app-proportional-cost [proportions]="proportionalCosts()"></app-proportional-cost>
        </div>
      </section>
      <hr>
      <section class="summary">
        <h1>Summary</h1>
        <div class="cost-cards">
          <app-cost-card
            title="Storage"
            icon="server"
            [cost]="totalsByCategory()?.Storage"
          ></app-cost-card>
          <app-cost-card
            title="Compute"
            icon="settings-gear"
            iconColor="secondary"
            [cost]="totalsByCategory()?.Compute"
          ></app-cost-card>
          <app-cost-card
            title="Infrastructure"
            icon="data-tree"
            iconColor="green"
            [cost]="totalsByCategory()?.TenantInfra"
          ></app-cost-card>
          <app-cost-card
            title="Other"
            icon="servers-database"
            iconColor="orange"
            [cost]="totalsByCategory()?.Network"
          ></app-cost-card>
        </div>
        <app-billing-chart
          [series]="series()"
          [categories]="timeGroups()"
        ></app-billing-chart>
      </section>
    </div>
  </div>
</app-theme>
