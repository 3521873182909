import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { computed, Injectable, Signal } from '@angular/core';
import {
  CreateMutationResult,
  CreateQueryResult,
  injectMutation,
  injectQuery,
  injectQueryClient,
  MutationOptions,
} from '@tanstack/angular-query-experimental';
import { lastValueFrom, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  OrgUser,
  Paginated,
  User,
  UsersRequestOptions,
  VerifyInviteResponse,
} from '../../models/users.types';
import { ActiveOrgService } from '../active-org/active-org.service';
import { buildSearchParams } from '../service-utils';

interface UpdateOrgSelfPayload {
  org_id: string;
  job_titles: string[];
}

interface UpdateUserPayload {
  org_id: string;
  user_id: string;
  organization_role: string;
  status: string;
}

interface InsertUserPayload {
  org_id: string;
  users: {
    given_name: string;
    family_name: string;
    email: string;
    organization_role: string;
    job_titles: string[];
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private client;

  constructor(
    private http: HttpClient,
    private activeOrgService: ActiveOrgService,
  ) {
    this.client = injectQueryClient();
  }
  private updateUser(payload: UpdateUserPayload): Observable<User> {
    return this.http.put<User>(`${environment.apiBaseUrl}/organizations/${payload.org_id}/users/${payload.user_id}`, {
      organization_role: payload.organization_role,
      status: payload.status,
    });
  }

  injectOrgUserUpdateMutation(options?: MutationOptions<User, HttpErrorResponse, UpdateUserPayload>): CreateMutationResult<User, HttpErrorResponse, UpdateUserPayload> {
    return injectMutation(() => ({
      ...options,
      mutationFn: (payload: UpdateUserPayload) => {
        return lastValueFrom(this.updateUser(payload));
      },
      onSuccess: (data, variable, context) => {
        this.client.invalidateQueries({ queryKey: ['users'] });
        options?.onSuccess?.(data, variable, context);
      },
    }));
  }

  private updateOrgUserDetails(payload: UpdateOrgSelfPayload): Observable<User> {
    return this.http.put<User>(`${environment.apiBaseUrl}/organizations/${payload.org_id}/users/self`, {
      job_titles: payload.job_titles,
    });
  }

  injectOrgUserDetailsUpdateMutation(options?: MutationOptions<User, HttpErrorResponse, UpdateOrgSelfPayload>): CreateMutationResult<User, HttpErrorResponse, UpdateOrgSelfPayload> {
    return injectMutation(() => ({
      ...options,
      mutationFn: (payload: UpdateOrgSelfPayload) => {
        return lastValueFrom(this.updateOrgUserDetails(payload));
      },
      onSuccess: (data, variable, context) => {
        this.client.invalidateQueries({ queryKey: ['users'] });
        options?.onSuccess?.(data, variable, context);
      },
    }));
  }

  getOrgUsers(orgId: string, options: UsersRequestOptions = { size: 10 }): Observable<Paginated<OrgUser>> {
    return this.http.get<Paginated<OrgUser>>(`${environment.apiBaseUrl}/organizations/${orgId}/users?${buildSearchParams(options)}`);
  }

  injectOrgUsersQuery(options: Signal<UsersRequestOptions>, orgId?: string): CreateQueryResult<Paginated<OrgUser>, Error> {
    const orgIdToFetch = computed(() => {
      return orgId || this.activeOrgService.org()?.organization_id || ''
    });

    return injectQuery(() => ({
      // we should be calling the options signal in the query key but there's a circular dependency issue
      // along with manual re-fetches being triggered
      queryKey: ['users', orgIdToFetch(), options],
      enabled: Boolean(orgIdToFetch()),
      queryFn: () => {
        return lastValueFrom(this.getOrgUsers(orgIdToFetch(), options()));
      },
    }));
  }

  private insertUsers(payload: InsertUserPayload): Observable<OrgUser[]> {
    return this.http.post<OrgUser[]>(`${environment.apiBaseUrl}/organizations/${payload.org_id}/users`, payload.users);
  }

  injectInsertUsersMutation(options?: MutationOptions<OrgUser[], HttpErrorResponse, InsertUserPayload>): CreateMutationResult<OrgUser[], HttpErrorResponse, InsertUserPayload> {
    return injectMutation(() => ({
      ...options,
      mutationFn: (payload: InsertUserPayload) => {
        return lastValueFrom(this.insertUsers(payload));
      },
      onSuccess: (data, variable, context) => {
        this.client.invalidateQueries({ queryKey: ['users'] });
        options?.onSuccess?.(data, variable, context);
      },
    }));
  }

  public verifyInvite(orgId: string, invite: string): Observable<HttpResponse<VerifyInviteResponse>> {
    return this.http.get<VerifyInviteResponse>(`${environment.apiBaseUrl}/organizations/${orgId}/verify/${invite}`, { observe: 'response' });
  }

  public resendInvite(orgId: string, userId: string): Observable<string> {
    return this.http.post<string>(`${environment.apiBaseUrl}/organizations/${orgId}/users/${userId}/invite`, {});
  }
}
