import { Component, computed, effect } from '@angular/core';
import { Router } from '@angular/router';
import { getOrgSlug } from 'src/app/guards/valid-org.guard';
import { Organization } from 'src/app/models/organization';
import { orgRoleNameMap } from 'src/app/models/users.types';
import { AuthenticationService } from 'src/app/services/authentication.service';

import { getOrgInitials, stringToAvatarColor } from '../../services/organizations/organizations.service';
import { SelfService } from '../../services/self/self.service';

@Component({
  selector: 'app-org-selector',
  templateUrl: './org-selector.component.html',
  styleUrls: ['./org-selector.component.scss'],
})
export class OrgSelectorComponent {

  // need to bind these to template
  roleNameMap = orgRoleNameMap;
  protected readonly stringToAvatarColor = stringToAvatarColor;
  protected readonly getOrgInitials = getOrgInitials;

  constructor(
    public selfService: SelfService,
    private router: Router,
    private authService: AuthenticationService,
  ) {}

  selfQuery = this.selfService.selfQuery;
  displayDialog = false;

  activeOrgsSignal = computed(() =>
    this.selfService.selfSignal()?.organizations.filter(org => org.status === 'active') || [],
  );

  navigateIfOnlyOneOrgEffect = effect(() => {

    if (!this.activeOrgsSignal()) {
      return
    }
    else if (this.activeOrgsSignal()?.length === 1) {
      this.navigateToOrg(this.activeOrgsSignal()[0]);
    }
    else {
      // this is needed to stop the dialog from popping in
      // as we wait for the navigation to happen
      this.displayDialog = true;
    }
  })

  navigateToOrg(org: Organization): void {
    const slug = getOrgSlug(org);
    this.router.navigate(['org', slug, 'dashboard']);
  }

  logout(): void {
    this.authService.logout();
  }
}

