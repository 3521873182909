import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, combineLatest, filter, of, switchMap, tap } from 'rxjs';

import { ExpandedUser } from '../models/users.types';
import { SelfService } from '../services/self/self.service';

/**
 * Hits the manager service endpoint `/users/verify` which checks that the user exists within the DB
 * @returns false on any status other than 200
 */
export const verifyUserGuard: CanActivateFn = () => {
  const selfService = inject(SelfService);
  const router = inject(Router);
  const auth = inject(AuthService);

  const self$ = toObservable(selfService.selfSignal);

  const error$ = toObservable(selfService.selfQuery.error);

  return combineLatest([self$, error$]).pipe(
    switchMap(([self, error]) => {
      if(error) {
        throw error;
      }
      return of(self);
    }),
    filter((self): self is ExpandedUser => Boolean(self)),
    switchMap(user => {
      const hasOrgs = user.organizations && user.organizations.length > 0;
      const hasActiveOrgs = user.organizations.some(org => org.status === 'active');

      return of(hasOrgs && hasActiveOrgs);
    }),
    tap(isAuthorized => {
      if (!isAuthorized) {
        throw new Error('User has no active organizations');
      }
    }),
    catchError((errorResponse) => {
      if (errorResponse?.status === 401) {
        auth.loginWithRedirect({
          authorizationParams: {
            prompt: 'login', // forces re-login
          },
        });
      } else {
        router.navigate(['login-failure']);
      }
      return of(false);
    }),
  );
}
