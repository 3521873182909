import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FwSnackbarService } from '@flywheel-io/vision';

import { SelfService } from '../../../services/self/self.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  updateSelfMutation;
  selfSignal;

  constructor(
    public dialogRef: DialogRef,
    public selfService: SelfService,
    public fb: FormBuilder,
    public snackbarService: FwSnackbarService,
  ) {

    this.selfSignal = this.selfService.selfSignal;
    this.updateSelfMutation = this.selfService.injectUpdateSelfMutation({
      onSuccess: () => {
        this.snackbarService.show({
          severity: 'success',
          message: 'Successfully updated your profile!',
        })
        this.dialogRef.close();
      },
      onError: () => {
        this.snackbarService.show({
          severity: 'error',
          message: 'Something went wrong while trying to update your profile.',
        })
      },
    })
  }

  profileForm = this.fb.group({
    firstName: this.fb.control('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(64),
    ]),
    lastName: this.fb.control('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(64),
    ]),
    email: this.fb.control(''),
  })

  ngOnInit(): void {


    this.profileForm.patchValue({
      firstName: this.selfSignal()?.given_name,
      lastName: this.selfSignal()?.family_name,
      email: this.selfSignal()?.email,
    })

    // re-disable the email input as patching it enables it
    this.profileForm.controls.email.disable();
  }

  onSave(): void {

    if (this.profileForm.valid) {
      this.updateSelfMutation.mutate({
        family_name: this.profileForm.value.lastName || '',
        given_name: this.profileForm.value.firstName || '',
      })
    }
  }

  getErrorMessage(controlName: 'firstName' | 'lastName'): string {
    const errors = this.profileForm.controls[controlName].errors;

    const controlNameToLabelMap = {
      'firstName': 'First name',
      'lastName': 'Last name',
    }

    if (errors?.['required']) {
      const controlLabel = controlNameToLabelMap[controlName];
      return `${controlLabel} is required`;
    }
    if (errors?.['maxlength']) {
      return 'Maximum length is 64 characters';
    }
    if (errors?.['minlength']) {
      return 'Minimum length is 2 characters';
    }

    return '';
  }

  close(): void {
    this.dialogRef.close();
  }
}
