<fw-dialog width="small" [showClose]="true">
  <fw-dialog-header>
      <h3>Edit User</h3>
  </fw-dialog-header>

  <fw-dialog-content [formGroup]="profileForm">
    <div>
      <label class="vision-h3">
        First Name
      </label>
      <fw-text-input [errorText]="getErrorMessage('firstName')" placeholder="Enter first name" formControlName="firstName"></fw-text-input>
    </div>

    <div>
      <label class="vision-h3">
        Last Name
      </label>
      <fw-text-input [errorText]="getErrorMessage('lastName')" placeholder="Enter last name" formControlName="lastName"></fw-text-input>
    </div>

    <div>
      <label class="vision-h3">
        Email
      <fw-text-input helperText="Reach out to your Org Admin to change your email on file." formControlName="email"></fw-text-input>
    </label>
    </div>
  </fw-dialog-content>

  <fw-dialog-actions>
     <fw-button variant="outline" (click)="close()">Cancel</fw-button>
     <fw-button [disabled]="!profileForm.valid || !profileForm.dirty" (click)="onSave()">Save</fw-button>
  </fw-dialog-actions>
</fw-dialog>
