import { Component } from '@angular/core';
import { FwDialogService } from '@flywheel-io/vision';

import { PermissionService } from '../../services/permission.service';
import { NewUserDialogComponent } from './new-user-dialog/new-user-dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {

  constructor(
    private permissions: PermissionService,
    public dialog: FwDialogService,
  ) {}

  canAddUsers = this.permissions.check('CREATE_ORG_USER');

  openNewUserDialog(): void {
    if (this.canAddUsers()) {
      this.dialog.openDialog(NewUserDialogComponent, {
        data: {},
      });
    }
  }

}
