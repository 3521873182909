import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FwSnackbarService } from '@flywheel-io/vision';
import { ActiveOrgService } from 'src/app/services/active-org/active-org.service';

import { orgRoleOptions, OrgUser } from '../../../models/users.types';
import { UsersService } from '../../../services/users/users.service';

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss'],
})
export class EditUserDialogComponent implements OnInit {
  protected readonly orgRoleOptions = orgRoleOptions;
  updateUserMutation;
  busy: boolean = false;

  constructor(
    public activeOrgService: ActiveOrgService,
    public usersService: UsersService,
    public snackbarService: FwSnackbarService,
    public fb: FormBuilder,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data?: OrgUser,
  ) {
    this.updateUserMutation = this.usersService.injectOrgUserUpdateMutation({
      onSuccess: () => {
        this.snackbarService.show({
          severity: 'success',
          message: 'User updated!',
        });
        this.dialogRef.close();
        this.busy = false;
      },
      onError: () => {
        this.busy = false;
        this.snackbarService.show({
          severity: 'error',
          message: 'Something went wrong during user update.',
        });
      },
    });
  }

  userForm = this.fb.group({
    given_name: this.fb.control({ value: '', disabled: true }, [Validators.required, Validators.max(32)]),
    family_name: this.fb.control({ value: '', disabled: true }, [Validators.required, Validators.max(32)]),
    email: this.fb.control({
      value: '',
      disabled: true,
    }, [Validators.required, Validators.pattern(/^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/)]),
    organization_role: this.fb.control('', [Validators.required]),
    job_titles: this.fb.array([]),
  });

  ngOnInit(): void {
    if (this.data) {
      this.userForm.patchValue({
        given_name: this.data.given_name,
        family_name: this.data.family_name,
        email: this.data.email,
        organization_role: this.data.organization_role || 'MEMBER',
        job_titles: [],
      });
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  handleSave(): void {
    const activeOrg = this.activeOrgService.org();
    const isOrgAdmin = this.activeOrgService.isOrgAdmin();

    if (activeOrg && isOrgAdmin && this.data) {
      if (this.userForm.valid) {
        this.busy = true;
        this.updateUserMutation.mutate({
          org_id: activeOrg.organization_id,
          user_id: this.data.user_id,
          organization_role: this.userForm.controls.organization_role.value!,
          status: this.data.status,
        });
      }
    } else {
      alert('Not Authorized');
    }
  }
}
