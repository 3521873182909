import { Component, computed, signal } from '@angular/core';
import { closestIndexTo, differenceInDays, differenceInYears, eachDayOfInterval, eachMonthOfInterval, eachYearOfInterval, format, isSameMonth, isSameYear, startOfMonth, subDays } from 'date-fns';
import { ApexAxisChartSeries } from 'ng-apexcharts';

import { BillingService } from './billing.service';
import { BillingCategory, BillingFilterState, BillingLineItem, CategoryTotals, TimeGroups, TimeMode } from './billing.types';

type TimeBucket = {
  date: Date,
  lineItems: BillingLineItem[],
  categories: CategoryTotals
}

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent {
  billingQuery;
  billingData;

  constructor(
    private billingService: BillingService,
  ) {
    this.billingQuery = this.billingService.injectBillingQuery(this.filters);
    this.billingData = this.billingQuery.data;
  }

  onFilterChange(change: BillingFilterState): void {
    this.filters.set(change);
  }


  // bind for the template
  formatDate = format;

  filters = signal<BillingFilterState>({
    range: {
      start: startOfMonth(new Date()),
      end: subDays(new Date(), 1), // yesterday
    },
    tenantIds: [],
  });

  totalsByCategory = computed(() => {
    const categories: CategoryTotals = {
      Compute: 0,
      Storage: 0,
      Network: 0,
      TenantInfra: 0,
    }

    return this.billingData()?.reduce<typeof categories>(
      (proportions, current) => {
        proportions[current.category] += current.total_cost;
        return proportions;
      },
      categories,
    );
  })

  proportionalCosts = computed(() => {
    const categoryTotals = this.totalsByCategory();
    const totalCost = this.totalCost();

    // portions withe percentage as an integer
    // start with everything even
    const portions: CategoryTotals = {
      Compute: 25,
      Storage: 25,
      Network: 25,
      TenantInfra: 25,
    }

    if (!categoryTotals || !totalCost) {
      return portions
    }

    const calculatedPortions: Record<BillingCategory, number> = {
      Compute: Math.floor((categoryTotals.Compute / totalCost) * 100),
      Storage: Math.floor(categoryTotals.Storage / totalCost * 100),
      TenantInfra: Math.floor((categoryTotals.TenantInfra / totalCost) * 100),
      Network: Math.floor((categoryTotals.Network / totalCost) * 100),
    }

    const categories = Object.keys(calculatedPortions) as BillingCategory[]

    // there's a chance that with the rounding we don't add up to a hundred percent,
    // this figures out how much space we have left and adds the diff to the largest category
    const totalPercentage = categories.reduce((acc, key) => (acc + calculatedPortions[key]), 0);
    const diffToHundred = 100 - totalPercentage;

    if(diffToHundred) {
      const highestPortion = Math.max(...categories.map(cat => calculatedPortions[cat]));
      const highestCategory = categories.find(cat => calculatedPortions[cat] === highestPortion);

      if(highestCategory) {
        calculatedPortions[highestCategory] += diffToHundred;
      }
    }

    return calculatedPortions;
  })

  totalCost = computed(() => {
    return this.billingData()?.reduce<number>((accumulator, current) => (accumulator + current.total_cost), 0);
  })

  timeGroups = computed<TimeGroups>(() => {

    const { range } = this.filters();

    const groupByMonths = Math.abs(differenceInDays(range.start, range.end)) > 50;
    const groupByYears = Math.abs(differenceInYears(range.start, range.end)) >= 2;

    if (groupByYears) {
      return {
        groups: eachYearOfInterval(range),
        mode: 'year',
      }
    }

    if (groupByMonths) {
      return {
        groups: eachMonthOfInterval(range),
        mode: 'month',
      }
    }

    // default to grouping by days
    return {
      groups: eachDayOfInterval(this.filters().range),
      mode: 'day',
    }
  })

  series = computed<ApexAxisChartSeries>(() => {

    // make sure we have data
    const range = this.timeGroups();
    const bills = this.billingData();

    if (!bills || !range) {
      return [];
    }

    // create buckets for each unit of time in our series
    const billsGroupedByTime: TimeBucket[] = range.groups.map(day => {
      return {
        date: day,
        lineItems: [],
        categories: {
          Compute: 0,
          Storage: 0,
          Network: 0,
          TenantInfra: 0,
        },
      }
    })

    // sort each lineItem into the correct time group
    for (const lineItem of bills) {

      const findTimeBucketIndex = (lineItem: BillingLineItem, groups: Date[], mode: TimeMode): number | undefined => {
        const findIndexFunctionMap = {
          'day': () => closestIndexTo(lineItem.date, groups),
          'month': () => range.groups.findIndex(group => isSameMonth(group, lineItem.date)),
          'year': () => range.groups.findIndex(group => isSameYear(group, lineItem.date)),
        }
        return findIndexFunctionMap[mode]();
      }

      const timeBucketIndex = findTimeBucketIndex(lineItem, range.groups, range.mode);

      // need to explicitly check undefined as 0 is valid
      if (timeBucketIndex !== undefined) {
        // push the line item and add up total by category
        const correctBucket = billsGroupedByTime[timeBucketIndex];
        correctBucket.lineItems.push(lineItem);
        correctBucket.categories[lineItem.category] += lineItem.total_cost;
      }
    }

    // there's probably a way to do this with less iterations, but this is simple
    // map 0s to null as that hides the border for empty data points on the chart
    const storageSeries = billsGroupedByTime.map(day => Math.floor(day.categories.Storage) || null);
    const computeSeries = billsGroupedByTime.map(day => Math.floor(day.categories.Compute) || null);
    const infraSeries = billsGroupedByTime.map(day => Math.floor(day.categories.TenantInfra) || null)
    const otherSeries = billsGroupedByTime.map(day => Math.floor(day.categories.Network) || null)

    const series: ApexAxisChartSeries = [
      {
        name: 'Storage',
        data: storageSeries,
      },
      {
        name: 'Compute',
        data: computeSeries,
      },
      {
        name: 'Infrastructure',
        data: infraSeries,
      },
      {
        name: 'Other',
        data: otherSeries,
      },
    ]

    return series;
  })
}
