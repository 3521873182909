<app-theme [fitted]="true">
  <fw-layout-context
    width="medium"
    icon="user-delete-cross"
    iconColor="secondary"
    title="403 Forbidden"
    description="Looks like {{ userEmail | async }} is not set up to be a user within Flywheel. Contact your Org Admin to get registered to the platform or sign in using a different method."
  >
    <fw-button variant="outline" (click)="returnToSignIn()" [testId]="'btn-sign-in'">Return to Sign in</fw-button>
  </fw-layout-context>
</app-theme>
