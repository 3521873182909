import { Component, EventEmitter, Output } from '@angular/core';
import { FwDialogService } from '@flywheel-io/vision';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  @Output() themeChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(public dialog: FwDialogService) {
  }

  handleThemeChange(value: string): void {
    this.themeChange.emit(value);
  }

}
