import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink, RouterModule } from '@angular/router';
import {
  FwAppIconModule,
  FwAvatarModule,
  FwButtonModule, FwCardModule, FwDialogsModule, FwIconButtonModule,
  FwIconModule,
  FwLayoutsModule,
  FwMenuModule,
  FwSectionHeadingModule,
  FwSelectMenuModule,
  FwTextInputModule,
} from '@flywheel-io/vision';

import { TestIdDirective } from '../../directives/test-id.directive';
import { ThemeModule } from '../../theme/theme.module';
import { DashboardComponent } from './dashboard.component';
import { NewsComponent } from './news/news.component';
import { TenantDialogComponent } from './tenant-dialog/tenant-dialog.component';


@NgModule({
  imports: [
    CdkMenuModule,
    CommonModule,
    FormsModule,
    FwAppIconModule,
    FwAvatarModule,
    FwButtonModule,
    FwCardModule,
    FwDialogsModule,
    FwIconButtonModule,
    FwIconModule,
    FwLayoutsModule,
    FwMenuModule,
    FwSectionHeadingModule,
    FwSelectMenuModule,
    FwTextInputModule,
    RouterLink,
    TestIdDirective,
    ThemeModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    DashboardComponent,
    TenantDialogComponent,
    NewsComponent,
  ],
  exports: [
    DashboardComponent,
    FwMenuModule,
    FwButtonModule,
    CdkMenuModule,
    RouterModule,
    FwLayoutsModule,
  ],
})
export class DashboardModule {
}
