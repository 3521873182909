import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { map } from 'rxjs';

@Component({
  selector: 'app-invite-failure',
  templateUrl: './invite-failure.component.html',
  styleUrls: ['./invite-failure.component.scss'],
})
export class InviteFailureComponent {

  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  public userEmail = this.auth.user$.pipe(
    map(user => user?.email || 'your email'),
  );

  returnToSignIn(): void {
    this.auth.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }
}
