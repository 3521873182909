import { CdkMenuTrigger } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterLinkActive } from '@angular/router';
import {
  FwAlertModule, FwAvatarModule, FwBadgeModule,
  FwButtonModule, FwChipModule, FwDialogsModule, FwFormHeadingModule,
  FwIconButtonModule, FwIconModule, FwLayoutsModule, FwMenuModule, FwPaginatorModule,
  FwSectionHeadingModule, FwSelectMenuModule, FwSnackbarModule, FwTextInputModule,
} from '@flywheel-io/vision';

import { TestIdDirective } from '../../directives/test-id.directive';
import { ThemeModule } from '../../theme/theme.module';
import { NewOrganizationDialogComponent } from './new-organization-dialog/new-organization-dialog.component';
import { OrganizationsComponent } from './organizations.component';
import { OrganizationsTableComponent } from './organizations-table/organizations-table.component';
import { OrganizationsTableHeaderComponent } from './organizations-table/organizations-table-header/organizations-table-header.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CdkMenuTrigger,
    CommonModule,
    FormsModule,
    FwAlertModule,
    FwAvatarModule,
    FwBadgeModule,
    FwButtonModule,
    FwChipModule,
    FwDialogsModule,
    FwIconButtonModule,
    FwIconModule,
    FwMenuModule,
    FwPaginatorModule,
    FwSectionHeadingModule,
    FwSelectMenuModule,
    FwSnackbarModule,
    FwTextInputModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatSortModule,
    MatTableModule,
    ReactiveFormsModule,
    TestIdDirective,
    ThemeModule,
    FwLayoutsModule,
    RouterLinkActive,
    FwFormHeadingModule,
  ],
  declarations: [
    NewOrganizationDialogComponent,
    OrganizationsComponent,
    OrganizationsTableComponent,
    OrganizationsTableHeaderComponent,
  ],
  exports: [
    NewOrganizationDialogComponent,
    OrganizationsComponent,
    OrganizationsTableComponent,
  ],
})
export class OrganizationsModule {
}
