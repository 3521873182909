import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss'],
})
export class TenantsComponent {
  adminOrgId: string = '';

  constructor(
    private route: ActivatedRoute,
  ) {
    if (route.snapshot && route.snapshot.params) {
      this.adminOrgId = route.snapshot.params['admin_org'];
    }
  }

}
