import { Component, Input } from '@angular/core';
import { IconType } from '@flywheel-io/vision/components/icon/icon.types';

type IconColor = 'primary' | 'secondary' | 'red' | 'orange' | 'green' | 'slate' | 'muted' | 'typography';

@Component({
  selector: 'app-cost-card',
  templateUrl: './cost-card.component.html',
  styleUrls: ['./cost-card.component.scss'],
})
export class CostCardComponent {

  @Input() icon: IconType = 'icon-placeholder';
  @Input() iconColor: IconColor = 'primary';
  @Input() highlighted: boolean = false;
  @Input() title: string = 'Cost';
  @Input() date: string = '12/21/2157';
  @Input() cost?: number = 0;

  private USDFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 });

  formatUSD(amount: number): string {
    return this.USDFormat.format(amount);
  }
}
