import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FwSnackbarService, FwValidators } from '@flywheel-io/vision';

import { OrganizationsService } from '../../../services/organizations/organizations.service';

export interface DialogData {
  orgId?: string;
}

@Component({
  selector: 'app-new-organization-dialog',
  templateUrl: './new-organization-dialog.component.html',
  styleUrls: ['./new-organization-dialog.component.scss'],
})
export class NewOrganizationDialogComponent {
  insertOrgMutation;
  errorMessage?: string;
  busy: boolean = false;

  newOrganizationForm = this.fb.group({
    name: new FormControl('', [Validators.required, Validators.required, Validators.max(200)]),
    label: new FormControl('', [Validators.max(200)]),
    given_name: new FormControl('', [Validators.required, Validators.max(64)]),
    family_name: new FormControl('', [Validators.required, Validators.max(64)]),
    email: new FormControl('', [Validators.required, FwValidators.email]),
  });

  constructor(
    private orgServices: OrganizationsService,
    private fb: FormBuilder,
    public snackbarService: FwSnackbarService,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data?: DialogData,
  ) {
    this.insertOrgMutation = this.orgServices.injectInsertOrgMutation({
      onSuccess: () => {
        this.snackbarService.show({
          severity: 'success',
          message: 'Organization created!',
        });
        this.busy=false;
        this.dialogRef.close();
      },
      onError: (err) => {
        this.snackbarService.show({
          severity: 'error',
          message: 'Something went wrong during create.',
        });
        this.errorMessage = err.message+'. Possibly duplicate Organization Label.';
        this.busy=false;
      },
    });
  }

  handleAlertClose(): void {
    this.errorMessage = undefined;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  createOrganization(): void {
    if (this.newOrganizationForm.valid) {
      this.busy=true;
      // todo: initial user?
      this.insertOrgMutation.mutate({
        name: this.newOrganizationForm.controls.name.value!,
        label: this.newOrganizationForm.controls.label.value!,
        initial_user: {
          given_name: this.newOrganizationForm.controls.given_name.value!,
          family_name: this.newOrganizationForm.controls.family_name.value!,
          email: this.newOrganizationForm.controls.email.value!,
        },
      });
    }
  }
}
