<app-theme page="shaded" [fitted]="true">
  <fw-layout-panel border="all" [rounded]="true">
    <app-word-mark></app-word-mark>
    <div>
      <h3>Goodbye for now</h3>
      <p>You have successfully logged out.</p>
    </div>
    <fw-button variant="outline" [fullWidth]="true" (click)="gotoSignIn()" [testId]="'btn-sign-in'">Return to Sign
      in
    </fw-button>
  </fw-layout-panel>
</app-theme>
