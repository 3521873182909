<fw-dialog width="small" [showClose]="true">
  <fw-dialog-header>
      <h3>Edit User</h3>
  </fw-dialog-header>

  <fw-dialog-content [formGroup]="userForm">
    <section>
      <div>
        <label class="vision-h3">
          First Name
        </label>
        <fw-text-input
          class="input-first-name"
          placeholder="First Name"
          formControlName="given_name"
          [error]="userForm.controls['given_name'].errors !== null"
          [maxLength]="32"></fw-text-input>
      </div>

      <div>
        <label class="vision-h3">
          Last Name
        </label>
        <fw-text-input
          class="input-last-name"
          placeholder="Last Name"
          formControlName="family_name"
          [error]="userForm.controls['family_name'].errors !== null"
          [maxLength]="32"></fw-text-input>
      </div>

      <div>
        <label class="vision-h3">
          Email
        </label>
        <fw-text-input helperText="Contact Flywheel Support to change a Users Email." formControlName="email"></fw-text-input>
      </div>

      <div>
        <label class="vision-h3">
          Organization Role
        </label>
        <fw-select
          width="200px"
          formControlName="organization_role"
          [options]="orgRoleOptions"></fw-select>
      </div>
    </section>
  </fw-dialog-content>

  <fw-dialog-actions><div class="flex-space"></div>
     <fw-button (click)="close()" variant="outline" [testId]="'btn-edit-user-cancel'">Cancel</fw-button>
     <fw-button (click)="handleSave()" [disabled]="busy || !userForm.valid || !userForm.dirty" *ngIf="activeOrgService.isOrgAdmin"  [testId]="'btn-edit-user-edit'">Save</fw-button>
  </fw-dialog-actions>
</fw-dialog>
