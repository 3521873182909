import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss'],
})
export class ThemeComponent implements OnInit {
  @Input() layout: string = 'central';
  @Input() page: 'light' | 'shaded' = 'light';
  @Input() wrapped: boolean = false;
  @Input() fitted: boolean = false;

  title = 'project-vision';
  theme = 'vision-light-theme'; // set this to 'vision-dark-theme' to enable dark mode
  renderer: Renderer2;

  constructor(@Inject(DOCUMENT) document: Document, r: Renderer2) {
    this.renderer = r;
    r.addClass(document.body, this.theme);
  }

  ngOnInit(): void {
    this.theme = localStorage.getItem('flywheel-theme-preference') || '';
    if (this.theme === '') {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
        this.setTheme();
      });
    }
    this.setTheme();
  }

  handleThemeChange(value: string): void {
    this.theme = value;
    this.setTheme();
  }

  setTheme(): void {
    localStorage.setItem('flywheel-theme-preference', this.theme);
    this.renderer.removeClass(document.body, 'vision-dark-theme');
    this.renderer.removeClass(document.body, 'vision-light-theme');
    if (this.theme === '') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.renderer.addClass(document.body, 'vision-dark-theme');
      } else {
        this.renderer.addClass(document.body, 'vision-light-theme');
      }
    } else {
      this.renderer.addClass(document.body, this.theme);
    }
  }
}
