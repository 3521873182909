<app-theme [wrapped]="true">
  <fw-section-heading title="Users" [backButton]="true">
    <fw-button
      leftIcon="add-user"
      (click)="openNewUserDialog()"
      [disabled]="!canAddUsers()"
      [testId]="'btn-invite-users'">
      Invite Users
    </fw-button>
  </fw-section-heading>
  <app-users-table></app-users-table>
</app-theme>
