import { DialogRef } from '@angular/cdk/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogWidth, FwDialogService } from '@flywheel-io/vision';
import { ActiveOrgService } from 'src/app/services/active-org/active-org.service';

import { orgRoleOptions } from '../../../models/users.types';
import { UsersService } from '../../../services/users/users.service';
import { InvitationSentDialogComponent } from '../invitation-sent-dialog/invitation-sent-dialog.component';

@Component({
  selector: 'app-new-user-dialog',
  templateUrl: './new-user-dialog.component.html',
  styleUrls: ['./new-user-dialog.component.scss'],
})
export class NewUserDialogComponent implements OnInit {
  protected readonly DialogWidth = DialogWidth;
  protected readonly orgRoleOptions = orgRoleOptions;
  usersArray: FormArray<FormGroup> = new FormArray<FormGroup>([]);
  userSet: FormGroup = new FormGroup({
    users: this.usersArray,
  });
  isFormVetting = false;
  insertUsersMutation;
  errorMessage?: string;

  constructor(
    public dialogRef: DialogRef,
    public dialogService: FwDialogService,
    private usersService: UsersService,
    private fb: FormBuilder,
    private activeOrgService: ActiveOrgService,
  ) {
    this.insertUsersMutation = this.usersService.injectInsertUsersMutation({
      onSuccess: () => {
        this.dialogRef.close();
        this.dialogService.openDialog(InvitationSentDialogComponent);
      },
      onError: (err: HttpErrorResponse): void => {
        if (err.error && err.error.detail) {
          this.errorMessage = err.error.detail;
        } else {
          this.errorMessage = err.message;
        }
      },
    });
  }

  ngOnInit(): void {
    this.addRow();
  }

  addRow(): void {
    if (this.usersArray.length < 10) {
      this.usersArray.push(this.fb.group({
        given_name: new FormControl('', [Validators.required, Validators.max(32)]),
        family_name: new FormControl('', [Validators.required, Validators.max(32)]),
        email: new FormControl('', [Validators.required, Validators.max(127), Validators.pattern(/^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/)]),
        organization_role: new FormControl('MEMBER', [Validators.required]),
        job_titles: new FormArray([]),
      }));
    }
  }

  removeRow(index: number): void {
    if (index !== 0) {
      this.usersArray.removeAt(index);
    }
  }

  isFieldError(control: AbstractControl): boolean {
    if (this.isFormVetting) {
      return (control.errors !== null);
    } else {
      return false;
    }
  }

  handleAlertClose(): void {
    this.isFormVetting = false;
    this.errorMessage = undefined;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  send(): void {
    const activeOrg = this.activeOrgService.org();
    this.isFormVetting = true;

    if (this.userSet.valid && activeOrg) {
      this.insertUsersMutation.mutate({
        org_id: activeOrg.organization_id,
        users: this.usersArray.value,
      });
    }
  }
}
