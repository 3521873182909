import { CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, effect, HostListener, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FwDialogService } from '@flywheel-io/vision';
import { FwPaginatorEvent } from '@flywheel-io/vision/components/paginator/paginator.model';
import { CreateQueryResult } from '@tanstack/angular-query-experimental';
import { Paginated } from 'src/app/models/users.types';

import { getOrgSlug } from '../../../guards/valid-org.guard';
import { OrgRequestOptions } from '../../../models/organization';
import { ActiveOrgService } from '../../../services/active-org/active-org.service';
import { BasicOrganization, OrganizationsService } from '../../../services/organizations/organizations.service';

@Component({
  selector: 'app-organizations-table',
  templateUrl: './organizations-table.component.html',
  styleUrls: ['./organizations-table.component.scss'],
})
export class OrganizationsTableComponent implements OnInit {
  @HostListener('document:click') outsideClick(): void {
    if (this._isOpen && this.trigger) {
      this.trigger.close();
      this._isOpen = false;
    }
    if (this.trigger && this.trigger.isOpen()) {
      this._isOpen = true;
    }
  }

  @ViewChild('dsTbSort') dsTbSort = new MatSort();
  @ViewChild(CdkMenuTrigger) trigger?: CdkMenuTrigger;
  dataSource = new MatTableDataSource<BasicOrganization>();
  displayedColumns: string[] = [
    'name',
    'label',
    'tenant_count',
    // 'id', // hiding the tenants count until the endpoint includes it
  ];
  orgQuery: CreateQueryResult<Paginated<BasicOrganization>>;
  currentPageIndex: number = 0;
  pageSize: number = 25;
  paginatedData?: Paginated<BasicOrganization>;
  total: number = 0;
  options: WritableSignal<OrgRequestOptions>;
  filterText: string = '';
  private _isOpen = false;

  constructor(
    private activeOrgService: ActiveOrgService,
    private orgServices: OrganizationsService,
    public dialog: FwDialogService,
    private router: Router,
  ) {
    this.options = signal({ size: this.pageSize });
    this.orgQuery = this.orgServices.injectOrganizationsQuery(this.options);

    effect(() => {
      if (this.orgQuery) {
        const organizations = this.orgQuery.data()?.items || [];
        this.paginatedData = this.orgQuery.data();
        this.dataSource.data = organizations;
        this.dataSource.sort = this.dsTbSort;
        if (this.paginatedData?.total) {
          this.total = this.paginatedData?.total;
        }
      }
    });
  }

  ngOnInit(): void {
    this.dsTbSort.sort(({ id: 'name', start: 'asc' }) as MatSortable);
  }

  refresh(): void {
    if (this.orgQuery) {
      this.orgQuery?.refetch();
    }
    if (this.orgQuery) {
      this.orgQuery?.refetch();
    }
  }

  rowSelected(row: BasicOrganization): void {
    this.router.navigate(['org', getOrgSlug(this.activeOrgService.org()), 'admin', 'org', row.id, 'tenants']);
  }

  handleSearchChanged(searchTerm: string): void {
    const options: OrgRequestOptions = {
      size: this.pageSize,
      filter: searchTerm,
    };
    this.options.update(() => options);
    this.orgQuery.refetch().finally();
  }

  async handlePageEvent(evt: FwPaginatorEvent): Promise<void> {
    if (!this.orgQuery.isPending()) {
      const options: OrgRequestOptions = {
        size: this.pageSize,
        filter: this.filterText,
      };
      if (evt.pageSize !== this.pageSize) {
        // page size change
        options.size = evt.pageSize;
        this.currentPageIndex = 0;
        this.pageSize = evt.pageSize;
      } else if (evt.pageIndex > this.currentPageIndex) {
        // forward
        options.cursor = this.paginatedData?.next_cursor;
        this.currentPageIndex = evt.pageIndex;
      } else if (evt.pageIndex < this.currentPageIndex) {
        // back
        options.cursor = this.paginatedData?.previous_cursor;
        this.currentPageIndex = evt.pageIndex;
      }
      this.options.update(() => options);
      await this.orgQuery.refetch().finally();
    }
  }

}
