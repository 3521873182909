import { computed, Injectable, signal, WritableSignal } from '@angular/core';
import { FwDialogService } from '@flywheel-io/vision';
import * as sentry from '@sentry/angular';
import { Organization } from 'src/app/models/organization';

@Injectable({ providedIn: 'root' })
export class ActiveOrgService {

  constructor(
    public dialog: FwDialogService,
  ) {}

  org: WritableSignal<Organization | undefined> = signal(undefined);
  isOrgAdmin = computed(() => {
    const activeOrg = this.org();
    const role = activeOrg?.organization_role;

    return Boolean(role && ['ORG_ADMIN','STAFF_ADMIN'].includes(role));
  })

  isStaffAdmin = computed(() => {
    const activeOrg = this.org();
    const role = activeOrg?.organization_role;

    return Boolean(role && ['STAFF_ADMIN'].includes(role));
  })

  public setActiveOrg(org: Organization): void {
    const scope = sentry.getCurrentScope();
    scope.setTag('organization', org.name);
    scope.setTag('role', org.organization_role);
    this.org.set(org);
  }


}
