import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { catchError, of, switchMap, tap } from 'rxjs';

import { FeatureFlagService } from '../services/feature-flag/feature-flag.service';

/**
 * Checks the route's `data.flags` against all feature flags to ensure required flags are enabled
 */
export const featureFlagGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const flagsService = inject(FeatureFlagService);
  const router = inject(Router);

  // we need to parse the query param flags here
  // we can't rely on the feature flag service as we're inside a navigation event
  //.so the qParams don't get emitted to it
  const flagOverrides = flagsService.parseQueryParamsToFlags(route.queryParams);

  return flagsService.getApiFlags().pipe(
    switchMap(apiFlags => {
      const allFlags = {
        ...apiFlags,
        ...flagOverrides,
      }
      const requiredFlags = route.data['flags'] as string[];
      const allFlagsGood = requiredFlags.every(requiredFlag => allFlags[requiredFlag]);
      return of(allFlagsGood);
    }),
    tap(flagRequirementsMet => {
      if (!flagRequirementsMet) {
        throw new Error('Required Flags are not enabled');
      }
    }),
    catchError(() => {
      router.navigate(['404']);
      return of(false);
    }),
  );
}
