import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Sentry from '@sentry/angular';
import { BehaviorSubject, Observable } from 'rxjs';

import { User } from '../models/users.types';
import { SelfService } from './self/self.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userSubject: BehaviorSubject<User | null>;
  public user$: Observable<User>;
  public isAuthenticated: BehaviorSubject<boolean>;

  constructor(
    public selfService: SelfService,
    private authService: AuthService,
  ) {
    this.userSubject = new BehaviorSubject<User | null>(null);
    this.isAuthenticated = new BehaviorSubject(false);
    this.user$ = this.selfService.getSelf();
    //todo: chain this with authservice check
    this.user$.subscribe((user: User) => {
      this.userSubject.next(user);
      this.isAuthenticated.next(user !== null);
      const scope = Sentry.getCurrentScope();
      scope.setUser({ id: user.id, email: user.email, username: user.email });
    });
  }

  public get currentUser(): User | null {
    return this.userSubject.value;
  }

  login(): void {
    this.authService.loginWithRedirect();
  }

  logout(): void {
    this.isAuthenticated.next(false);
    this.userSubject.next(null);
    this.authService.logout({
      logoutParams: {
        returnTo: `${window.location.origin}/logged-out`,
      },
    });
  }
}
