import { AuthConfig } from '@auth0/auth0-angular';

import { environment as env, environment } from '../../environments/environment';

const allowedList: Array<string> = [
  'token/info',
  'users/*',
  'api/*',
];

export class ApiConfig {
  api_base?: string = env.apiBaseUrl;
  allowedListUrls = allowedList;
}

export const auth0Configuration: AuthConfig = {
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  ...env.auth,
  httpInterceptor: {
    allowedList: [
      {
        uri: `${environment.apiBaseUrl}*`,
      },
    ],
  },
};
