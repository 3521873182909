import { Component, Input } from '@angular/core';
import { format } from 'date-fns';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexLegend,
  ApexNoData,
  ApexPlotOptions,
  ApexResponsive,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
} from 'ng-apexcharts';

import { TimeGroups } from '../billing.types';
import { customTooltip } from './custom-tooltip';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  legend: ApexLegend;
  fill: ApexFill;
  grid: ApexGrid;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  noData: ApexNoData;
};

@Component({
  selector: 'app-billing-chart',
  templateUrl: './billing-chart.component.html',
})
export class BillingChartComponent {
  public chartOptions: ChartOptions;

  @Input()
  set series(incoming: ApexAxisChartSeries) {
    this.chartOptions.series = incoming;
  }

  @Input()
  set categories(timeGroups: TimeGroups) {

    const capitalize = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);
    const axisTitle = capitalize(timeGroups.mode);

    const dateFormatterFnMap: { [key: string ]: (arg: string) => string} = {
      'month': (date) => format(date, 'MMMM'),
      'year': (date) => format(date, 'yyyy'),
      'day': (date) => format(date, 'd'),
    }

    // need to reset at the xaxis level as setting deeper options dodges change detection
    this.chartOptions.xaxis = {
      ...this.chartOptions,
      title: {
        text: axisTitle,
      },
      categories: timeGroups.groups,
      labels: {
        formatter: dateFormatterFnMap[timeGroups.mode],
      },
    };
  }

  constructor() {

    this.chartOptions = {
      series: [],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          hideZeroBarsWhenGrouped: true,
        },
      },
      xaxis: {
        type: 'category',
        title: {
          text: 'Day',
          style: {
            fontFamily: 'Inter, sans',
            color: 'var(--typography-base)',
          },
        },
        categories: [],
        labels: {
          formatter: (date) => {
            return new Date(date).getDate().toString();
          },
          style: {
            fontFamily: 'Inter, sans',
            colors: 'var(--typography-base)',
          },
        },
        axisBorder: {
          color: 'var(--separations-divider)',
        },
        axisTicks: {
          color: 'var(--separations-divider)',
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        title: {
          text: 'Cost',
          style: {
            fontFamily: 'Inter, sans',
            color: 'var(--typography-base)',
          },
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
      fill: {
        opacity: 1,
        colors: ['var(--primary-focus)', 'var(--secondary-focus)', 'var(--green-focus)', 'var(--orange-focus)'],
      },
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        width: 1,
        dashArray: 0,
        colors: ['var(--primary-border)', 'var(--secondary-border)', 'var(--green-border)', 'var(--orange-border)'],
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        custom: customTooltip,
        onDatasetHover: {
          highlightDataSeries: true,
        },
      },
      noData: {
        text: 'Loading...',
      },
    };
  }
}


