<fw-dialog width="small" [showClose]="true">
  <fw-dialog-header>
      <h3>Profile</h3>
  </fw-dialog-header>

  <fw-dialog-content>
    <section class="avatar">
      <fw-avatar content="initial" [initial]="selfSignal()?.initials" size="x-large"></fw-avatar>
      <fw-button variant="outline" [disabled]="true" [testId]="'btn-profile-edit-photo'">Edit Photo</fw-button>
    </section>

    <section class="info">
      <fw-chip color="green" title="Active" [selectable]="false"></fw-chip>

      <div>
        <h3>Name</h3>
        <p>{{selfSignal()?.fullName}}</p>
      </div>

      <div>
        <h3>Email</h3>
        <p>{{selfSignal()?.email}}</p>
      </div>

      <div>
        <h3>Role</h3>
        <p>{{selfSignal()?.role}}</p>
      </div>

      <div>
        <h3>Organization</h3>
        <p>{{selfSignal()?.organizationName}}</p>
      </div>
    </section>
  </fw-dialog-content>

  <fw-dialog-actions>
     <fw-button leftIcon="edit" variant="outline" [testId]="'btn-profile-edit'" (click)="editProfile()">Edit Profile</fw-button>
     <fw-button (click)="close()" [testId]="'btn-profile-done'">Done</fw-button>
  </fw-dialog-actions>
</fw-dialog>
