<fw-dialog [showClose]="true" [title]="'Invitation Sent'" width="small">
  <fw-dialog-content style="overflow-x: auto">
    <div class="body-content">
      <fw-icon color="primary" size="large">email</fw-icon>
      <h4>Invitation emails have sent!</h4>
      <p class="vision-p3">In most cases, it should just take a few minutes and invitation emails will be available in users inboxes.  Have your new users follow the instructions below.</p>
    </div>
    <fw-textarea-input [value]="instructions" [readOnly]="true" [minRows]="11" [maxRows]="11"></fw-textarea-input>
  </fw-dialog-content>
  <fw-dialog-actions>
    <fw-button variant="outline" leftIcon="add-new-create" (click)="addRow()" [testId]="'btn-add-user-another'">Add Another User
    </fw-button>
    <div class="flex"></div>
    <fw-button variant="outline" leftIcon="copy-item" (click)="copyInstructions()" [testId]="'btn-copy-instructions'">Copy Instructions</fw-button>
    <fw-button (click)="close()" [testId]="'btn-done'">Done</fw-button>
  </fw-dialog-actions>
</fw-dialog>
