import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { computed, Injectable, Signal } from '@angular/core';
import {
  CreateMutationResult,
  CreateQueryResult,
  injectMutation,
  injectQuery,
  injectQueryClient,
  MutationOptions,
} from '@tanstack/angular-query-experimental';
import { lastValueFrom, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Permission } from '../../models/organization';
import {
  ExpandedUser,
  User,
} from '../../models/users.types';
import { ActiveOrgService } from '../active-org/active-org.service';

interface UpdateSelfPayload {
  given_name: string;
  family_name: string;
}

@Injectable({
  providedIn: 'root',
})
export class SelfService {
  private client;
  public selfQuery;
  public selfSignal;

  constructor(
    private http: HttpClient,
    private activeOrgService: ActiveOrgService,
  ) {
    this.client = injectQueryClient();
    this.selfQuery = this.injectSelfQuery();
    this.selfSignal = this.selfQuery.data;
  }

  /* Self */
  getSelf(): Observable<User> {
    return this.http.get<User>(`${environment.apiBaseUrl}/users/self`);
  }

  /**
 *  Checks that the user has specified permission
 * @param permission permission string to check for
 * @param orgId *optional* falls back to current active org
 * @returns a signal of whether or not the user has the permission
 */
  checkPermission(permission: Permission, orgId?: string): Signal<boolean> {
    return computed(() => {

      const self = this.selfSignal();
      const relevantOrgId = orgId || this.activeOrgService.org()?.organization_id;
      const relevantOrg = self?.organizations.find((org) => org.organization_id === relevantOrgId);
      const activeInOrg = relevantOrg?.status === 'active';

      if (!relevantOrg || !activeInOrg) {
        return false;
      }

      return relevantOrg.organization_role_permissions.includes(permission);
    })
  }

  injectSelfQuery(): CreateQueryResult<ExpandedUser, Error> {
    return injectQuery(() => ({
      queryKey: ['self'],
      queryFn: () => lastValueFrom(this.getSelf()),
      select: (data) => {

        const activeOrg = this.activeOrgService.org() || data.organizations?.[0];

        return {
          ...data,
          initials: data.given_name[0] + data.family_name[0],
          fullName: `${data.given_name} ${data.family_name}`,
          role: activeOrg.friendlyRole || activeOrg.organization_role,
          organizationName: activeOrg?.name || '',
        };
      },
    }));
  }

  private updateSelf(payload: UpdateSelfPayload): Observable<User> {
    return this.http.put<User>(`${environment.apiBaseUrl}/users/self`, payload);
  }

  injectUpdateSelfMutation(options?: MutationOptions<User, HttpErrorResponse, UpdateSelfPayload>): CreateMutationResult<User, HttpErrorResponse, UpdateSelfPayload> {
    return injectMutation(() => ({
      ...options,
      mutationFn: (payload: UpdateSelfPayload) => {
        return lastValueFrom(this.updateSelf(payload));
      },
      onSuccess: (data, variable, context) => {
        this.client.invalidateQueries({ queryKey: ['self'] });
        this.client.invalidateQueries({ queryKey: ['users'] });
        options?.onSuccess?.(data, variable, context);
      },
    }));
  }
}
