import { Clipboard } from '@angular/cdk/clipboard';
import { DialogRef } from '@angular/cdk/dialog';
import { Component, ViewEncapsulation } from '@angular/core';
import { FwDialogService, FwSnackbarService } from '@flywheel-io/vision';

import { ActiveOrgService } from '../../../services/active-org/active-org.service';
import { NewUserDialogComponent } from '../new-user-dialog/new-user-dialog.component';

@Component({
  selector: 'app-invitation-sent-dialog',
  templateUrl: './invitation-sent-dialog.component.html',
  styleUrls: ['./invitation-sent-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InvitationSentDialogComponent {
  instructions: string =
    `You have a new Flywheel account for [${this.activeOrgService.org()?.name}].

Sign in to your Flywheel Account to access the Flywheel services your organization provides.

Please check your email.  You should see an invitation email from [no-reply@flywheel.com].  If you don’t see it, please check your spam folder.

From the invitation email, click “Take me to Flywheel” and then follow the sign-in instructions.`;

  constructor(
    private clipboard: Clipboard,
    private activeOrgService: ActiveOrgService,
    public dialogRef: DialogRef,
    public dialogService: FwDialogService,
    public snackbarService: FwSnackbarService,
  ) {
  }

  addRow(): void {
    this.dialogRef.close();
    this.dialogService.openDialog(NewUserDialogComponent);
  }

  copyInstructions(): void {
    this.clipboard.copy(this.instructions);
    this.snackbarService.show({
      severity: 'success',
      message: 'Instructions copied to clipboard!',
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
