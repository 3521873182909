import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FwDialogService } from '@flywheel-io/vision';
import { debounce } from 'lodash-es';

import { NewTenantDialogComponent } from '../../new-tenant-dialog/new-tenant-dialog.component';

@Component({
  selector: 'app-tenants-table-header',
  templateUrl: './tenants-table-header.component.html',
  styleUrls: ['./tenants-table-header.component.scss'],
})
export class TenantsTableHeaderComponent {
  @Input() orgId?: string;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() searchChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public dialog: FwDialogService,
  ) {
    this.handleSearchChange = debounce(this.handleSearchChange, 1000);
  }

  handleSearchChange(event: Event): void {
    if (event.target) {
      const searchInput = event.target as HTMLInputElement;
      this.searchChanged.emit(searchInput.value);
    }
  }

  openNewTenantDialog(): void {
    if (this.orgId) {
      this.dialog.openDialog(NewTenantDialogComponent, {
        data: {
          orgId: this.orgId,
        },
      });
    }
  }
}
