import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, of, switchMap, tap } from 'rxjs';

import { Permission } from '../models/organization';
import { PermissionService } from '../services/permission.service';
import { SelfService } from '../services/self/self.service';
import { getOrgSlug } from './active-org.guard';

/**
 * Checks that the current user has the passed permission(s)
 *
 * On Failure: Redirects to 403 page
 */
export const permissionGuard = (permissions: Permission | Permission[]): CanActivateFn => {
  return (route) => {
    const router = inject(Router);
    const permissionService = inject(PermissionService);
    const selfService = inject(SelfService);

    return selfService.getSelf().pipe(
      switchMap((self) => {

        const isStaffAdmin = self.organizations.map(org => org.organization_role).includes('STAFF_ADMIN');
        const relevantOrg = self.organizations?.find(org => getOrgSlug(org) === route.params['organization']);

        const hasOrgScopedPermission = permissionService.check(permissions, relevantOrg?.organization_id);

        return of(hasOrgScopedPermission() || isStaffAdmin);
      }),
      tap(hasPermission => {
        if (!hasPermission) {
          throw new Error('User doesn\'t have required permissions');
        }
      }),
      catchError(() => {
        router.navigate(['/permission-failure']).then(() => {});
        return of(false);
      }),
    );
  };
};
