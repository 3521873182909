<fw-dialog width="small" [showClose]="true" icon="data-tree" [title]="'New Tenant'">

  <fw-dialog-content style="overflow-x: auto">
    <div class="body-content" [formGroup]="newTenantForm">
      <fw-alert
        *ngIf="errorMessage" severity="error" icon="warning-circle" [title]="'Errors'" [showClose]="true"
        (close)="handleAlertClose()">
        {{ errorMessage }}
      </fw-alert>
      <fw-grid>
        <fw-grid-item [cols]="6">
          <fw-form-heading title="Name"></fw-form-heading>
          <fw-text-input placeholder="Tenant Name" formControlName="label"></fw-text-input>
        </fw-grid-item>
        <fw-grid-item [cols]="6">
          <fw-form-heading title="Release Channel"></fw-form-heading>
          <fw-select
            *ngIf="releaseChannels"
            [options]="releaseChannels"
            placeholder="Select Channel..."
            width="260px"
            formControlName="release_channel"
            valueProperty="name"
            titleProperty="name"
          >
          </fw-select>
        </fw-grid-item>
        <fw-grid-item [cols]="12">
          <fw-form-heading title="Notes"></fw-form-heading>
          <fw-textarea-input
            placeholder="Notes"
            formControlName="notes"
            [minRows]="7"
            [maxRows]="10"
            [maxlength]="1000"
          ></fw-textarea-input>
        </fw-grid-item>
      </fw-grid>
    </div>
  </fw-dialog-content>
  <fw-dialog-actions>
    <div class="flex"></div>
    <fw-button variant="outline" (click)="cancel()" [testId]="'btn-add-tenant-cancel'">Cancel</fw-button>
    <fw-button (click)="createTenant()" [testId]="'btn-add-tenant-send'" [disabled]="busy">Create Tenant</fw-button>
  </fw-dialog-actions>
</fw-dialog>
