<app-theme [fitted]="true">
<div id="splash">
  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.5391 3.56915C35.4808 3.42191 35.3944 3.28776 35.2848 3.17437C35.1753 3.06097 35.0449 2.97056 34.9009 2.90828C34.7569 2.846 34.6022 2.81309 34.4456 2.81141C34.2891 2.80973 34.1337 2.83932 33.9885 2.8985L10.1225 12.6255C9.90532 12.7146 9.71869 12.8664 9.58581 13.0621C9.45292 13.2578 9.37962 13.4887 9.375 13.7261V21.1289C9.3757 21.2832 9.40482 21.4359 9.46087 21.5794C9.51777 21.728 9.60312 21.8637 9.712 21.9787C9.82089 22.0938 9.95115 22.1858 10.0953 22.2496C10.2394 22.3133 10.3946 22.3476 10.5518 22.3503C10.7091 22.353 10.8653 22.3241 11.0115 22.2654L34.8775 12.5384C35.1003 12.4513 35.2921 12.2983 35.4281 12.0993C35.5641 11.9003 35.638 11.6644 35.6402 11.4224V4.01966C35.6346 3.8644 35.6004 3.71157 35.5391 3.56915ZM30.6498 17.018L10.1276 25.2808C9.90548 25.3708 9.71507 25.5262 9.58093 25.7269C9.44678 25.9277 9.37506 26.1646 9.375 26.407V36.134C9.37533 36.3711 9.44446 36.6028 9.57379 36.8004C9.70313 36.9979 9.88694 37.1525 10.1023 37.2449L17.0121 40.2244C17.1595 40.2859 17.3174 40.3173 17.4768 40.3166C17.6335 40.3173 17.7888 40.2865 17.9338 40.226C18.0787 40.1655 18.2104 40.0766 18.3212 39.9642C18.432 39.8519 18.5198 39.7184 18.5794 39.5716C18.6391 39.4247 18.6695 39.2673 18.6688 39.1084V32.6374C18.67 32.3945 18.7427 32.1575 18.8777 31.9568C19.0127 31.7561 19.2038 31.6009 19.4265 31.5111L31.5488 26.6732C31.7715 26.5834 31.9626 26.4282 32.0976 26.2275C32.2326 26.0269 32.3054 25.7899 32.3065 25.547V18.1698C32.3064 18.0172 32.2772 17.8659 32.2206 17.7244C32.1659 17.5715 32.0812 17.4314 31.9718 17.3125C31.8623 17.1935 31.7303 17.0982 31.5836 17.0323C31.4369 16.9663 31.2786 16.931 31.1181 16.9286C30.9577 16.9261 30.7984 16.9565 30.6498 17.018Z" fill="url(#paint0_linear_5001_49584)"/>
    <defs>
      <linearGradient id="paint0_linear_5001_49584" x1="3.43503" y1="48.6869" x2="33.8157" y2="-0.706245" gradientUnits="userSpaceOnUse">
        <stop stop-color="#093AF6"/>
        <stop offset="0.5" stop-color="#1B68FA"/>
        <stop offset="0.92" stop-color="#B080FC"/>
      </linearGradient>
    </defs>
  </svg>
  <div class="splash-loading-bar">
    <div class="splash-inner-bar"></div>
  </div>

</div>
</app-theme>
