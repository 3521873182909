import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, effect, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FwDialogService, FwSnackbarService } from '@flywheel-io/vision';
import { CreateQueryResult } from '@tanstack/angular-query-experimental';

import { ReleaseChannels } from '../../../models/release-channels';
import { Tenant } from '../../../models/tenant';
import { OrganizationsService } from '../../../services/organizations/organizations.service';
import { DeactivateTenantDialogComponent } from '../deactivate-tenant-dialog/deactivate-tenant-dialog.component';

@Component({
  selector: 'app-new-tenant-dialog',
  templateUrl: './edit-tenant-dialog.component.html',
  styleUrls: ['./edit-tenant-dialog.component.scss'],
})
export class EditTenantDialogComponent implements OnInit {
  orgReleaseChannelsQuery: CreateQueryResult<ReleaseChannels[]>;
  releaseChannels: ReleaseChannels[] = [];
  updateOrgTenantMutation;
  errorMessage?: string;
  orgIdSignal: WritableSignal<string>;
  busy: boolean = false;

  editTenantForm = this.fb.group({
    id: new FormControl(this.data?.id, [Validators.required]),
    org_id: new FormControl(this.data?.organization_id, [Validators.required]),
    release_channel: new FormControl({ value: this.data?.release_channel, disabled: true }, [Validators.required]),
    label: new FormControl(this.data?.label, [Validators.required, Validators.max(127)]),
    notes: new FormControl(this.data?.notes, [Validators.max(1000)]),
  });

  constructor(
    private orgServices: OrganizationsService,
    private fb: FormBuilder,
    public snackbarService: FwSnackbarService,
    private dialogService: FwDialogService,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data?: Tenant,
  ) {
    this.orgIdSignal = signal(this.data!.organization_id!);
    this.orgReleaseChannelsQuery = this.orgServices.injectOrgReleaseChannelsQuery(this.orgIdSignal);
    this.updateOrgTenantMutation = this.orgServices.injectUpdateOrgTenantMutation({
      onSuccess: () => {
        this.snackbarService.show({
          severity: 'success',
          message: 'Tenant updated!',
        });
        this.busy = false;
        this.dialogRef.close();
      },
      onError: (err) => {
        this.snackbarService.show({
          severity: 'error',
          message: 'Something went wrong during update.',
        });
        this.errorMessage = `${err.error.detail}`;
        this.busy = false;
      },
    });

    effect(() => {
      if (this.orgReleaseChannelsQuery) {
        this.releaseChannels = this.orgReleaseChannelsQuery.data() || [];
      }
    });

    this.editTenantForm.valueChanges.subscribe(value => {
      this.orgIdSignal.set(value.org_id!);
    });
  }

  handleAlertClose(): void {
    this.errorMessage = undefined;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  getErrorMessage(controlName: 'label' | 'notes'): string {
    const errors = this.editTenantForm.controls[controlName].errors;

    const controlNameToLabelMap = {
      'label': 'Name',
      'notes': 'Notes',
    };

    if (errors?.['required']) {
      const controlLabel = controlNameToLabelMap[controlName];
      return `${controlLabel} is required`;
    }
    if (errors?.['maxlength']) {
      return 'Maximum length is too long';
    }

    return '';
  }

  updateTenant(): void {
    if (this.editTenantForm.valid) {
      this.busy = true;
      this.updateOrgTenantMutation.mutate({
        tenant_id: this.editTenantForm.controls.id.value!,
        org_id: this.editTenantForm.controls.org_id.value!,
        release_channel: this.editTenantForm.controls.release_channel.value!,
        label: this.editTenantForm.controls.label.value!,
        notes: this.editTenantForm.controls.notes!.value!,
      });
    }
  }

  verifyDeactivation(): void {
    this.dialogRef.close();
    this.dialogService.openDialog(DeactivateTenantDialogComponent, { data: this.data });
  }

  ngOnInit(): void {
    if(this.data && this.data.deployment_status!=='deployed'){
      this.editTenantForm.get('label')?.disable();
      this.editTenantForm.get('notes')?.disable();
    }
  }
}
