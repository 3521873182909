import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, filter, of, switchMap } from 'rxjs';

import { Organization } from '../models/organization';
import { ExpandedUser } from '../models/users.types';
import { ActiveOrgService } from '../services/active-org/active-org.service';
import { BasicOrganization, OrganizationsService } from '../services/organizations/organizations.service';
import { SelfService } from '../services/self/self.service';

export const getOrgSlug = (org?: Organization | BasicOrganization): string => {

  if (!org) {
    return '';
  }

  return org.name.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '') // remove any non-alphanumeric chars
    .replace(/[\s_-]+/g, '-') // switch whitespace and _ to -
    .replace(/^-+|-+$/g, ''); // clean up - at the beginning and end
};

/**
 * Checks that the org the user is trying to navigate to can be mapped to an actual org, and sets it as the active org in ActiveOrgService
 */
export const activeOrgGuard: CanActivateFn = (route) => {
  const selfService = inject(SelfService);
  const router = inject(Router);
  const activeOrgService = inject(ActiveOrgService);
  const organizationService = inject(OrganizationsService);

  const self$ = toObservable(selfService.selfSignal);

  return self$.pipe(
    // ignore the initial 'undefined' emission from the signal
    filter((self): self is ExpandedUser => Boolean(self)),
    switchMap((self) => {

      // First check if they're a member of the org they're requesting
      const validOrg = self.organizations?.find(org => getOrgSlug(org) === route.params['organization']);

      if (validOrg?.status === 'active') {
        activeOrgService.setActiveOrg(validOrg as Organization);
        return of(true);
      }

      // Secondly if their an admin see if we can find the org at all
      const isStaffAdmin = (self as ExpandedUser).organizations.some(org => org.organization_role.includes('STAFF_ADMIN'));

      if (isStaffAdmin) {
        return organizationService.getOrganizations({ size: 500 }).pipe(
          switchMap(organizations => {
            const foundOrg = organizations.items.find((org => getOrgSlug(org) === route.params['organization']));

            if (foundOrg) {
              // we need to morph the simple org to a full org
              activeOrgService.setActiveOrg({
                organization_id: foundOrg.id,
                name: foundOrg.name,
                label: foundOrg.label,
                status: 'active',
                organization_role: 'STAFF_ADMIN',
                // assume they have all permissions
                // TODO: replace this with their platform permissions
                organization_role_permissions: [
                  'CREATE_ORG',
                  'CREATE_ORG_USER',
                  'CREATE_USER',
                  'CREATE_TENANT',
                  'GET_BILLING',
                  'GET_ORG',
                  'GET_ORG_USER',
                  'GET_RELEASE_CHANNELS',
                  'GET_TENANT',
                  'GET_USER',
                  'GET_SELF',
                  'UPDATE_USER',
                  'UPDATE_ORG_USER',
                  'UPDATE_ORG_USER_SELF',
                  'UPDATE_SELF',
                  'UPDATE_TENANT',
                ],
                tenants: [],
                friendlyRole: 'Viewing as Admin',
              });
              return of(true);
            }

            return of(false);
          }),
        );
      }

      return of(false);

    }),
    switchMap(result => {
      // switch false to a navigation away
      if (!result) {
        return of(router.parseUrl('/switch-org'));
      }
      return of(result);
    }),
    catchError(() => {
      // redirect away if any thing goes wrong
      return of(router.parseUrl('/switch-org'));
    }),
  );
};
