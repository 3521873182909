import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { map } from 'rxjs';

import { SelfService } from '../../services/self/self.service';

@Component({
  selector: 'app-login-failure',
  templateUrl: './login-failure.component.html',
  styleUrls: ['./login-failure.component.scss'],
})
export class LoginFailureComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private usersService: SelfService,
  ) {
  }

  public userEmail = this.auth.user$.pipe(
    map(user => user?.email || 'your email'),
  );

  returnToSignIn(): void {
    this.auth.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  ngOnInit(): void {
    if (this.auth && this.auth.isAuthenticated$) {
      this.auth.isAuthenticated$.subscribe(authenticated => {
        if (authenticated) {
          this.usersService.getSelf().subscribe(user => {
            if (user && user.organizations.length > 0) {
              if (user.organizations.some(org => org.status === 'active')) {
                window.location.href = `${window.location.origin}/`;
              }
            }
          });
        }
      });
    }
  }
}
