<div class="table-header">
  <fw-text-input
    leftIcon="search"
    placeholder="Search"
    (input)="handleSearchChange($event)">
  </fw-text-input>
  <fw-layout-toolbar>
    <fw-button
      variant="outline"
      leftIcon="rotate-refresh"
      (click)="refresh.emit(true)">
      Refresh
    </fw-button>
    <fw-button
      leftIcon="add-new-create"
      (click)="openNewOrganizationDialog()"
      [testId]="'btn-new-organization'">
      Add Organization
    </fw-button>
  </fw-layout-toolbar>
</div>
