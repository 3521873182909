import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, of, switchMap, tap } from 'rxjs';

import { SelfService } from '../services/self/self.service';

/**
 * Checks that the user has an active STAFF_ADMIN role
 * @returns false and redirects back to the dashboard if none found.
 */
export const verifyStaffGuard: CanActivateFn = () => {
  const selfService = inject(SelfService);
  const router = inject(Router);

  return selfService.getSelf().pipe(
    switchMap(user => {
      const hasOrgs = user.organizations && user.organizations.length > 0;
      const hasActiveStaffOrgs = user.organizations.some(org => org.status === 'active' && org.organization_role==='STAFF_ADMIN');

      return of(hasOrgs && hasActiveStaffOrgs);
    }),
    tap(isAuthorized => {
      if (!isAuthorized) {
        throw new Error('User has no active staff organizations');
      }
    }),
    catchError(() => {
      router.navigate(['/permission-failure']);
      return of(false);
    }),
  );
}
