import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { OrganizationsComponent } from './admin/organizations/organizations.component';
import { TenantsComponent } from './admin/tenants/tenants.component';
import { AcceptInviteComponent } from './features/accept-invite/accept-invite.component';
import { BillingComponent } from './features/billing/billing.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { InviteFailureComponent } from './features/invite-failure/invite-failure.component';
import { LoggedOutComponent } from './features/logged-out/logged-out.component';
import { LoginFailureComponent } from './features/login-failure/login-failure.component';
import { NotFoundComponent } from './features/not-found/not-found.component';
import { OnboardComponent } from './features/onboard/onboard.component';
import { OrgSelectorComponent } from './features/org-selector/org-selector.component';
import { UsersComponent } from './features/users/users.component';
import { featureFlagGuard } from './guards/feature-flag.guard';
import { validOrgGuard } from './guards/valid-org.guard';
import { verifyStaffGuard } from './guards/verify-staff.guard';
import { verifyUserGuard } from './guards/verify-user.guard';

const routes: Routes = [
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: 'logged-out',
    component: LoggedOutComponent,
  },
  {
    path: 'login-failure',
    component: LoginFailureComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'invite',
    component: AcceptInviteComponent,
  },
  {
    path: 'invite-failure',
    component: InviteFailureComponent,
  },
  {
    path: 'onboard',
    component: OnboardComponent,
  },
  {
    path: 'select-org',
    component: OrgSelectorComponent,
    canActivate: [AuthGuard, verifyUserGuard],
  },
  {
    path: 'org/:organization',
    canActivate: [AuthGuard, verifyUserGuard, validOrgGuard],
    pathMatch: 'prefix',
    children: [
      {
        path: 'billing',
        component: BillingComponent,
        canActivate: [featureFlagGuard],
        data: {
          flags: ['billing_frontend'],
        },
      },
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'admin',
        canActivate: [AuthGuard, verifyStaffGuard],
        pathMatch: 'prefix',
        children: [
          { path: 'org', component: OrganizationsComponent },
          { path: 'org/:admin_org/tenants', component: TenantsComponent },
          { path: 'tenants', component: TenantsComponent },
        ],
      },
    ],
  },
  { path: 'dashboard', redirectTo: 'select-org', pathMatch: 'full' },
  { path: '', redirectTo: 'select-org', pathMatch: 'full' },
  { path: '**', redirectTo: '404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
