import { Component, Input } from '@angular/core';

import { CategoryTotals } from '../billing.types';

@Component({
  selector: 'app-proportional-cost',
  templateUrl: './proportional-cost.component.html',
  styleUrls: ['./proportional-cost.component.scss'],
})
export class ProportionalCostComponent {
  @Input() proportions: CategoryTotals = {
    Storage: 25,
    Compute: 25,
    TenantInfra: 25,
    Network: 25,
  };
}
