<app-theme [fitted]="true">
  <fw-layout-context
    width="medium"
    icon="user-document"
    iconColor="secondary"
    title="Couldn't verify the invitation"
    description="We couldn’t verify your invite.  Please check that you are using the email address by which you were invited. If you are still seeing this page contact your organization administrator to ask for the new invitation."
  >
    <fw-button variant="outline" (click)="returnToSignIn()" [testId]="'btn-sign-in'">Return to Sign in</fw-button>
    <fw-button variant="outline" color="primary" leftIcon="rotate-refresh">Retry</fw-button>
  </fw-layout-context>
</app-theme>
