<ng-container *ngIf="!feedQuery.isPending(); then newsStrip else emptyStrip"></ng-container>

<ng-template #emptyStrip>
  <fw-grid>
    <fw-grid-item [cols]="12">
      <fw-subsection-heading icon="document-file-list-protocol" title="News">
        <p class="vision-p3"><a href="https://flywheel.io/insights/flywheel-news-product-highlights" target="_blank">Show
          All</a></p>
      </fw-subsection-heading>
    </fw-grid-item>

    <fw-grid-item [cols]="6" [sm]="4" [md]="3" *ngFor="let i of [1,2,3,4]">
      <fw-card>
        <fw-card-header>
          <h3 style="margin: 0">&nbsp;</h3>
        </fw-card-header>
        <fw-card-content>
          <div class="item-thumbnail fw-ghost-animation"></div>
          <p style="-webkit-line-clamp: 3; height: 60px"></p>
        </fw-card-content>
        <fw-card-footer>
          <fw-button
            [disabled]="true"
            [fullWidth]="true" variant="outline">
            View
          </fw-button>
        </fw-card-footer>
      </fw-card>
    </fw-grid-item>

  </fw-grid>
</ng-template>

<ng-template #newsStrip>
  <fw-grid>
    <fw-grid-item [cols]="12">
      <fw-subsection-heading icon="document-file-list-protocol" title="News">
        <p class="vision-p3"><a href="https://flywheel.io/insights/flywheel-news-product-highlights" target="_blank">Show
          All</a></p>
      </fw-subsection-heading>
    </fw-grid-item>
    <fw-grid-item [cols]="6" [sm]="4" [md]="3" *ngFor="let item of feedData; index as i">
      <fw-card>
        <fw-card-header [title]="item.title">
        </fw-card-header>
        <fw-card-content>
          <div
            class="item-thumbnail"
            [ngStyle]="item.thumbnail_url?{'background-image': 'url('+item.thumbnail_url+')'}:null">
          </div>
          <p style="-webkit-line-clamp: 3; height: 60px">
            {{ item.description || '' }}
          </p>
        </fw-card-content>
        <fw-card-footer>
          <fw-button
            [testId]="'btn-view-news-'+i"
            [fullWidth]="true" variant="outline" (click)="openUrl(item.url)">
            View
          </fw-button>
        </fw-card-footer>
      </fw-card>
    </fw-grid-item>
  </fw-grid>
</ng-template>
