import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-accept-invite',
  template: '',
  styles: [],
})
export class AcceptInviteComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(qParams => {

      const searchParams = new URLSearchParams(qParams);
      const onboardURL = `/onboard?${searchParams.toString()}`;

      this.authService.loginWithRedirect({
        appState: {
          target: onboardURL,
        },
        authorizationParams: {
          prompt: 'login', // forces re-login
        },
      })
    })
  }

}
