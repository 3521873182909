import { Component, effect, signal, WritableSignal } from '@angular/core';
import { CreateQueryResult } from '@tanstack/angular-query-experimental';

import { FeedItem, FeedStreamItems, FeedStreamRequestOptions } from '../../../models/stream.types';
import { FeedStreamsService } from '../../../services/feed/streams.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent {
  feedData: FeedItem[] = [];
  feedQuery: CreateQueryResult<FeedStreamItems>;
  options: WritableSignal<FeedStreamRequestOptions> = signal({});

  constructor(
    private feedStreamsService: FeedStreamsService,
  ) {
    this.feedQuery = this.feedStreamsService.injectFeedStreamItemsQuery(this.options);

    effect(() => {
      const items = this.feedQuery.data();
      if (items && items.data && items.data.length > 0) {
        this.feedData = items.data.reverse().filter((item: FeedItem, i: number) => i < 4);
      }
    });
  }

  openUrl(url: string): void {
    window.open(url);
  }
}
