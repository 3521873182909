import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, filter, of, switchMap } from 'rxjs';

import { Organization } from '../models/organization';
import { ExpandedUser } from '../models/users.types';
import { ActiveOrgService } from '../services/active-org/active-org.service';
import { SelfService } from '../services/self/self.service';

export const getOrgSlug = (org?: Organization): string => {

  if(!org) {
    return '';
  }

  return org.name.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '') // remove any non-alphanumeric chars
    .replace(/[\s_-]+/g, '-') // switch whitespace and _ to -
    .replace(/^-+|-+$/g, ''); // clean up - at the beginning and end
}

/**
 * Hits the manager service endpoint `/users/verify` which checks that the user exists within the DB
 * @returns false on any status other than 200
 */
export const validOrgGuard: CanActivateFn = (route) => {
  const selfService = inject(SelfService);
  const router = inject(Router);
  const activeOrgService = inject(ActiveOrgService);

  const self$ = toObservable(selfService.selfSignal);

  return self$.pipe(
    filter((self): self is ExpandedUser => Boolean(self)),
    switchMap((self) => {

      // check if we can map the route param to an org on self
      const validOrg = self.organizations?.find(org => getOrgSlug(org) === route.params['organization']);

      if (validOrg?.status !== 'active' || !validOrg) {
        router.navigate(['select-org']);
        return of(false);
      }

      activeOrgService.setActiveOrg(validOrg);
      return of(true)
    }),
    catchError(() => {
      return of(false);
    }),
  );
};
