import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { AuthModule } from '@auth0/auth0-angular';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import {
  FwAvatarModule,
  FwButtonModule,
  FwCardModule,
  FwContainedInputModule,
  FwLayoutsModule,
  FwSelectMenuModule,
  FwTextInputModule,
} from '@flywheel-io/vision';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Sentry from '@sentry/angular';
import { AngularQueryDevtools } from '@tanstack/angular-query-devtools-experimental'
import { provideAngularQuery, QueryClient } from '@tanstack/angular-query-experimental';

import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TestIdDirective } from './directives/test-id.directive';
import { AcceptInviteComponent } from './features/accept-invite/accept-invite.component';
import { BillingModule } from './features/billing/billing.module';
import { DashboardModule } from './features/dashboard/dashboard.module';
import { InviteFailureComponent } from './features/invite-failure/invite-failure.component';
import { LoggedOutComponent } from './features/logged-out/logged-out.component';
import { LoginFailureComponent } from './features/login-failure/login-failure.component';
import { NotFoundComponent } from './features/not-found/not-found.component';
import { OnboardComponent } from './features/onboard/onboard.component';
import { OrgSelectorComponent } from './features/org-selector/org-selector.component';
import { UsersModule } from './features/users/users.module';
import { RequestIdInterceptor } from './http-interceptors/request-id.interceptor';
import { auth0Configuration } from './models/api-config.model';
import { ThemeModule } from './theme/theme.module';

@NgModule({
  declarations: [
    AcceptInviteComponent,
    AppComponent,
    InviteFailureComponent,
    LoggedOutComponent,
    LoggedOutComponent,
    LoginFailureComponent,
    NotFoundComponent,
    OnboardComponent,
    OrgSelectorComponent,
  ],
  imports: [
    environment.devTools ? AngularQueryDevtools : [],
    AdminModule,
    AppRoutingModule,
    AuthModule.forRoot(auth0Configuration),
    BillingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    DashboardModule,
    FwAvatarModule,
    FwButtonModule,
    FwCardModule,
    FwLayoutsModule,
    FwSelectMenuModule,
    FwTextInputModule,
    HttpClientModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    TestIdDirective,
    ThemeModule,
    UsersModule,
    FwContainedInputModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA], // needed for conditional import of devtools
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestIdInterceptor, multi: true },
    provideHttpClient(withFetch()),
    provideAngularQuery(new QueryClient()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}
