import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { ActiveOrgService } from 'src/app/services/active-org/active-org.service';
import { environment } from 'src/environments/environment';

import { Tenant } from '../../../models/tenant';

@Component({
  selector: 'app-tenant-dialog',
  templateUrl: './tenant-dialog.component.html',
  styleUrls: ['./tenant-dialog.component.scss'],
})
export class TenantDialogComponent {

  constructor(
    public dialogRef: DialogRef,
    private activeOrgService: ActiveOrgService,
    @Inject(DIALOG_DATA) public fragment?: string,
  ) {

  }

  cancel(): void {
    this.dialogRef.close();
  }

  getTenantUrl(t: Tenant): string {
    if (t.deployment_status === 'deployed') {
      const fragment = this.fragment ? `/#${this.fragment}` : '';
      return `https://${t.path + environment.tenantBaseUrl}${fragment}`;
    } else {
      return '#';
    }
  }

  getTenants(): Tenant[] {
    const statusOrder = ['scheduled', 'deploying', 'deployed', 'undeployed', 'pending_deactivation', 'deactivated'];
    const tenants = this.activeOrgService.org()?.tenants || [];
    tenants.sort((a, b) => {
      if (statusOrder.indexOf(a.deployment_status) > statusOrder.indexOf(b.deployment_status)) {
        return 1;
      }
      if (statusOrder.indexOf(a.deployment_status) < statusOrder.indexOf(b.deployment_status)) {
        return -1;
      }
      if (a.label.toUpperCase() > b.label.toUpperCase()) {
        return 1;
      }
      if (a.label.toUpperCase() < b.label.toUpperCase()) {
        return -1;
      }
      return 0;
    });
    return tenants;
  }

  getDisplayStatusName(status: string): string {
    switch (status) {
      case 'deactivated':
        return 'Deactivated';
      case 'deploying':
        return 'Deploying';
      case 'pending_deactivation':
        return 'Pending Deactivation';
      case 'scheduled':
        return 'Scheduled to Deploy';
      case 'undeployed':
        return 'Not Deployed';
      case 'deployed':
        return 'Active';
      default:
        return '';
    }
  }
}
