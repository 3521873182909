import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class RequestIdInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const uuid = window.crypto.randomUUID();

    const newReq = req.clone({
      headers: req.headers.append('x-request-id', uuid),
    })

    return next.handle(newReq);
  }
}
