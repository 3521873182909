<fw-dialog [width]="DialogWidth.Large" [showClose]="true">
  <fw-dialog-header>
    <fw-icon>add-user</fw-icon>
    <h3>Add New User</h3>
  </fw-dialog-header>
  <fw-dialog-content style="overflow-x: auto">
    <div class="body-content">
      <p>
        Invite colleagues individually or up to ten at a time with the, "Add Another User" button.
      </p>
    <fw-alert *ngIf="errorMessage || (isFormVetting && !userSet.valid)" severity="error" icon="warning-circle" [title]="'Errors'" [showClose]="true" (close)="handleAlertClose()">
      {{errorMessage || 'Please make updates to the highlighted names and email addresses below.'}}
    </fw-alert>
    <form [formGroup]="userSet" (ngSubmit)="send()">
      <table formArrayName="users">
        <thead>
        <tr>
          <th style="width: 23%; max-width: 235px"><h5>First Name</h5></th>
          <th style="width: 23%; max-width: 250px"><h5>Last Name</h5></th>
          <th style="width: 32%"><h5>Email</h5></th>
          <th><h5>Role</h5></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let user of usersArray.controls; let userIndex = index"
          [formGroupName]="userIndex">
          <td>
            <fw-text-input
              class="input-first-name"
              placeholder="First Name"
              formControlName="given_name"
              [error]="isFieldError(user.controls['given_name'])"
              [maxLength]="32"></fw-text-input>
          </td>
          <td>
            <fw-text-input
              class="input-last-name"
              placeholder="Last Name"
              formControlName="family_name"
              [error]="isFieldError(user.controls['family_name'])"
              [maxLength]="32"></fw-text-input>
          </td>
          <td>
            <fw-text-input
              class="input-email"
              placeholder="Email"
              formControlName="email"
              [maxLength]="130"
              [error]="isFieldError(user.controls['email'])"
              type="email"></fw-text-input>
          </td>
          <td>
            <fw-select
              width="200px"
              formControlName="organization_role"
              [options]="orgRoleOptions"></fw-select>
          </td>
          <td>
            <fw-icon-button icon="trash-delete" (click)="removeRow(userIndex)" [disabled]="userIndex===0"></fw-icon-button>
          </td>
        </tr>
        </tbody>
      </table>
    </form>
    </div>
  </fw-dialog-content>
  <fw-dialog-actions>
    <fw-button variant="outline" leftIcon="add-new-create" (click)="addRow()" [testId]="'btn-add-user-another'" [disabled]="usersArray.length>=10">Add Another User
    </fw-button>
    <div class="flex"></div>
    <fw-button variant="outline" (click)="cancel()" [testId]="'btn-add-user-cancel'">Cancel</fw-button>
    <fw-button (click)="send()"  [disabled]="isFormVetting && (!userSet.valid || !userSet.dirty)" [testId]="'btn-add-user-send'">Send</fw-button>
  </fw-dialog-actions>
</fw-dialog>
