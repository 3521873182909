import { Component, EventEmitter, Output } from '@angular/core';
import { FwDialogService } from '@flywheel-io/vision';
import { debounce } from 'lodash-es';

import { NewOrganizationDialogComponent } from '../../new-organization-dialog/new-organization-dialog.component';

@Component({
  selector: 'app-organizations-table-header',
  templateUrl: './organizations-table-header.component.html',
  styleUrls: ['./organizations-table-header.component.scss'],
})
export class OrganizationsTableHeaderComponent {
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() searchChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public dialog: FwDialogService,
  ) {
    this.handleSearchChange = debounce(this.handleSearchChange, 1000);
  }

  handleSearchChange(event: Event): void {
    if (event.target) {
      const searchInput = event.target as HTMLInputElement;
      this.searchChanged.emit(searchInput.value);
    }
  }

  openNewOrganizationDialog(): void {
    const newOrgDialog = this.dialog.openDialog(NewOrganizationDialogComponent, {
      data: {},
    });
    newOrgDialog.closed.subscribe(() => {
      this.refresh.emit(true);
    });
  }
}
