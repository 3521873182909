import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { FwDialogService } from '@flywheel-io/vision';

import { SelfService } from '../../../services/self/self.service';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss'],
})
export class ProfileDialogComponent {

  constructor(
    public selfService: SelfService,
    public dialogService: FwDialogService,
    public dialogRef: DialogRef,
  ) {}
  selfSignal = this.selfService.selfSignal;


  editProfile(): void {
    this.dialogRef.close();
    this.dialogService.openDialog(EditProfileComponent);
  }

  close(): void {
    this.dialogRef.close();
  }
}
