<fw-layout-toolbar>
  <fw-icon-button
    color="primary"
    icon="bookmark"
    size="small"
    *ngIf="false"
    [testId]="'menu-bookmarks-trigger'"
    [cdkMenuTriggerFor]="bookmarksMenu"
    [cdkMenuPosition]="[{originX: 'start', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: 15, offsetX: -50}]"
  >
  </fw-icon-button>
  <ng-template #bookmarksMenu>
    <fw-menu-container width="200px" shadow="large">
      <fw-menu>
        <fw-menu-header>Bookmarks</fw-menu-header>
        <fw-menu-item
          [testId]="'menu-item-bookmark1'"
          title="Bookmark 1"
          icon="open"
          href="/"
          routerLinkActive="focused">
        </fw-menu-item>
        <fw-menu-item
          [testId]="'menu-item-bookmark2'"
          title="Bookmark 2"
          icon="open"
          href="/">
        </fw-menu-item>
        <fw-menu-item
          [testId]="'menu-item-bookmark3'"
          title="Bookmark 3"
          icon="open"
          href="/"
          routerLinkActive="focused">
        </fw-menu-item>
      </fw-menu>
    </fw-menu-container>
  </ng-template>
  <fw-icon-button
    color="primary"
    icon="question-circle"
    size="small"
    [testId]="'menu-help-trigger'"
    [cdkMenuTriggerFor]="helpMenu"
  >
  </fw-icon-button>
  <ng-template #helpMenu>
    <fw-menu-container shadow="large" offset="15px">
      <fw-menu>
        <fw-menu-item
          [testId]="'menu-item-docs'"
          title="Flywheel Documentation" href="https://docs.flywheel.io/"
          target="_blank"></fw-menu-item>
        <fw-menu-item
          [testId]="'menu-item-forum'"
          title="Flywheel Forum" href="https://forum.flywheel.io/"
          target="_blank"></fw-menu-item>
        <fw-menu-item
          [testId]="'menu-item-support'"
          title="Support"
          href="https://flywheelio.zendesk.com/hc/en-us/requests/new"
          target="_blank"></fw-menu-item>
      </fw-menu>
    </fw-menu-container>
  </ng-template>
  <fw-icon-button
    color="primary"
    icon="setting-gear"
    size="small"
    [testId]="'menu-settings-trigger'"
    [cdkMenuTriggerFor]="settingsMenu"
  >
  </fw-icon-button>
  <ng-template #settingsMenu>
    <fw-menu-container shadow="large" offset="15px">
      <fw-menu>
        <fw-menu-item [routerLink]="['/org', getActiveOrgSlug(), 'users']" [testId]="'menu-item-users'" title="Users" icon="user-group"></fw-menu-item>
        <fw-menu-item [routerLink]="['/select-org']" *ngIf="this.activeOrgs().length > 1" [testId]="'menu-item-select-org'" title="Switch Organization" icon="arrange-filter-sort"></fw-menu-item>
        <a *ngIf="flagsService.billingEnabled()" [routerLink]="['/org', getActiveOrgSlug(), 'billing']" queryParamsHandling="preserve"><fw-menu-item title="Billing" icon="money-dollar"></fw-menu-item></a>
        <fw-menu-item [routerLink]="['/org', getActiveOrgSlug(), 'admin', 'org']" *ngIf="isStaffAdmin()" title="Organizations Admin" icon="user-profile-group-discovery"></fw-menu-item>
        <fw-menu-separator></fw-menu-separator>
      </fw-menu>
      <fw-menu [value]="theme" (change)="handleThemeChange($event)" [multiSelect]="false" [useCheckbox]="true">
        <fw-menu-header>Theme</fw-menu-header>
        <fw-menu-item [testId]="'menu-item-theme'" title="Light" value="vision-light-theme"></fw-menu-item>
        <fw-menu-item [testId]="'menu-item-theme'" title="Dark" value="vision-dark-theme"></fw-menu-item>
        <fw-menu-item [testId]="'menu-item-theme'" title="Match Browser" value=""></fw-menu-item>
      </fw-menu>
    </fw-menu-container>
  </ng-template>
  <fw-avatar
    content="initial"
    [initial]="selfSignal()?.initials"
    size="medium"
    [testId]="'menu-profile-trigger'"
    [cdkMenuTriggerFor]="profileMenu"
  >
  </fw-avatar>
  <ng-template #profileMenu>
    <fw-menu-container shadow="large" width="220px" offset="13px">
      <div class="profile-display">
        <fw-avatar
          size="large"
          variant="circular"
          content="initial"
          initial="{{ selfSignal()?.initials }}"
        ></fw-avatar>
        <h4>{{ selfSignal()?.fullName }}</h4>
        <p>{{ selfSignal()?.email }}</p>
        <p>{{ activeOrgService.org()?.name }}</p>
      </div>
      <fw-menu>
        <fw-menu-separator></fw-menu-separator>
        <fw-menu-item [testId]="'menu-item-profile'" icon="user" title="Profile" size="compact" (click)="openUserDialog()"></fw-menu-item>
        <fw-menu-item [testId]="'menu-item-logout'" icon="logout" title="Log out" size="compact" (click)="logout()"></fw-menu-item>
      </fw-menu>
    </fw-menu-container>
  </ng-template>
</fw-layout-toolbar>
