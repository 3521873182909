<fw-dialog width="extra-small" [showClose]="true">
  <fw-dialog-header>
    <h3>Select Flywheel Core Tenant</h3>
  </fw-dialog-header>
  <fw-dialog-content>
    <p *ngIf="fragment!=='/exchange/data'">
      Please select the tenant you would like to work within Core.
      Once in Core, you can change your tenant at any time.
    </p>
    <p *ngIf="fragment==='/exchange/data'">
      Please select the core tenant with which you would like to
      view the Exchange. Once in Core, you can change your
      tenant at any time.
    </p>
    <fw-menu-container
      *ngIf="getTenants().length>0"
      width="100%" shadow="none"
      maxHeight="40vh"
      [showFilter]="getTenants().length>10">
      <fw-menu>
        <fw-menu-item
          *ngFor="let t of getTenants()"
          [disabled]="t.deployment_status!=='deployed'"
          [value]="t.path"
          [title]="t.label"
          [description]="getDisplayStatusName(t.deployment_status)"
          >
          <p *ngIf="t.deployment_status!=='scheduled'">
            <a [href]="getTenantUrl(t)" [target]="t.path" (click)="dialogRef.close()">
              <fw-button
                *ngIf="t.deployment_status!=='deploying'"
                [disabled]="t.deployment_status!=='deployed'"
                variant="outline"
                rightIcon="arrow-forward">
                Launch
              </fw-button>
            <fw-button
              *ngIf="t.deployment_status==='deploying'"
              [disabled]="true"
              variant="outline">
              <mat-spinner diameter="18"></mat-spinner>
            </fw-button>
            </a>
          </p>
        </fw-menu-item>
      </fw-menu>
    </fw-menu-container>
    <fw-layout-panel *ngIf="getTenants().length===0" border="all">
      <fw-layout-context
        icon="data-tree"
        title="There are no tenants within this organization."
        description="You need to add a tenant to your organization before you can a Flywheel product.  If you are expecting to see a tenant here reach out to your admin."
      >
      </fw-layout-context>
    </fw-layout-panel>
  </fw-dialog-content>
  <fw-dialog-actions>
    <fw-button variant="outline" (click)="cancel()" [testId]="'btn-cancel-tenant-dialog'">Close</fw-button>
  </fw-dialog-actions>
</fw-dialog>
