import { Component } from '@angular/core';
import { FwDialogService } from '@flywheel-io/vision';

import { ActiveOrgService } from '../../services/active-org/active-org.service';
import { NewUserDialogComponent } from './new-user-dialog/new-user-dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {

  constructor(
    protected activeOrgService: ActiveOrgService,
    public dialog: FwDialogService,
  ) {

  }

  openNewUserDialog(): void {
    if (this.activeOrgService.isOrgAdmin()) {
      this.dialog.openDialog(NewUserDialogComponent, {
        data: {},
      });
    }
  }

}
