<fw-dialog width="small" [showClose]="true">
  <fw-dialog-header>
      <h3>User Information</h3>
  </fw-dialog-header>

  <fw-dialog-content>
    <section class="avatar">
      <fw-avatar content="initial" [initial]="initials" size="x-large"></fw-avatar>
      <fw-button *ngIf="status==='pending'" (click)="handleResend()" variant="ghost" [testId]="'btn-edit-user-resend-invite'">Resend Invite</fw-button>
      <fw-button [disabled]="true" *ngIf="status==='pending'" (click)="handleRevoke()" variant="ghost" color="danger" [testId]="'btn-edit-user-revoke-invite'">Revoke Invite</fw-button>
    </section>

    <section class="info">
      <fw-chip *ngIf="status==='active'" color="green" title="Active" [selectable]="false"></fw-chip>
      <fw-chip *ngIf="status==='deactivated'" color="slate" title="Deactivated" [selectable]="false"></fw-chip>
      <fw-chip *ngIf="status==='pending'" color="secondary" title="Pending Invite" [selectable]="false"></fw-chip>

      <div>
        <h3>Name</h3>
        <p>{{fullName || '...'}}</p>
      </div>

      <div>
        <h3>Email</h3>
        <p>{{email || '...'}}</p>
      </div>

      <div>
        <h3>Role</h3>
        <p>{{role || '...'}}</p>
      </div>

      <div>
        <h3>User ID</h3>
        <p>{{userId || '...'}}</p>
      </div>
    </section>
  </fw-dialog-content>

  <fw-dialog-actions>
<!--    <fw-button (click)="handleDelete()" [disabled]="updateUserMutation.isPending() || data?.organization_role==='STAFF_ADMIN'" *ngIf="status==='pending' && activeOrgService.isOrgAdmin" variant="outline" color="danger" leftIcon="trash-delete" [testId]="'btn-edit-user-delete'">Delete User</fw-button>-->
    <fw-button (click)="handleDeactivate()" [disabled]="updateUserMutation.isPending() || data?.organization_role==='STAFF_ADMIN'" *ngIf="status==='active' && activeOrgService.isOrgAdmin() && authService.currentUser?.id !== userId" variant="outline" color="danger" leftIcon="close-circled" [testId]="'btn-edit-user-deactivate'">Deactivate User</fw-button>
    <fw-button (click)="handleReactivate()" [disabled]="updateUserMutation.isPending()" *ngIf="status==='deactivated' && activeOrgService.isOrgAdmin" variant="outline" color="danger" leftIcon="done-check" [testId]="'btn-edit-user-reactivate'">Reactivate User</fw-button>
    <div class="flex-space"></div>
     <fw-button (click)="close()" variant="outline" [testId]="'btn-edit-user-cancel'">Cancel</fw-button>
     <fw-button (click)="handleEdit()" [disabled]="updateUserMutation.isPending() || data?.organization_role==='STAFF_ADMIN'" *ngIf="activeOrgService.isOrgAdmin() && authService.currentUser?.id !== userId" [testId]="'btn-edit-user-edit'">Edit User Information</fw-button>
  </fw-dialog-actions>
</fw-dialog>
