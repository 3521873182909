import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[testId]',
  standalone: true,
})
export class TestIdDirective implements OnInit {
  @Input() testId?: string;

  constructor(private elem: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    if (this.testId) {
      this.renderer.setAttribute(this.elem.nativeElement, 'test-id', this.testId);
    }
  }
}
