<div class="table-wrapper" fwMenuCloseTriggers>
  <app-tenants-table-header [orgId]="orgId" (refresh)="refresh()" (searchChanged)="handleSearchChanged($event)"></app-tenants-table-header>
  <mat-progress-bar mode="query" *ngIf="orgTenantsQuery && (orgTenantsQuery.isLoading() || orgTenantsQuery.isRefetching())"></mat-progress-bar>
  <div class="table-pane">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="deployment_status"
      matSortDirection="desc"
      #dsTbSort="matSort"
      class="site-name">
      <ng-container matColumnDef="path">
        <mat-header-cell *matHeaderCellDef mat-sort-header [testId]="'table-header-path'">
          <h4>Path</h4>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="no-wrap">
          <fw-icon size="small" color="primary">data-tree</fw-icon>
          &nbsp;
          <p>{{ row.path }}</p>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="label">
        <mat-header-cell *matHeaderCellDef mat-sort-header [testId]="'table-header-label'">
          <h4>Label</h4>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="no-wrap flex-cell">
          <p class="p2 link" [title]="row.label">{{ row.label }}</p>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="release_channel">
        <mat-header-cell *matHeaderCellDef mat-sort-header [testId]="'table-header-release-channel'">
          <h4>Release Channel</h4>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="no-wrap">
          <p>{{ row.release_channel }}</p>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="deployment_status">
        <mat-header-cell *matHeaderCellDef mat-sort-header [testId]="'table-header-deployment-status'">
          <h4>Status</h4>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="no-wrap">
          <fw-chip color="green" *ngIf="row.deployment_status==='deployed'" [title]="'Deployed'"></fw-chip>
          <fw-chip color="slate" *ngIf="row.deployment_status==='deploying'" [title]="'Deploying'"></fw-chip>
          <fw-chip color="red" *ngIf="row.deployment_status==='deactivated'" [title]="'Deactivated'"></fw-chip>
          <fw-chip color="red" *ngIf="row.deployment_status==='error'" [title]="'Error'"></fw-chip>
          <fw-chip color="secondary" *ngIf="row.deployment_status==='pending_deactivation'" [title]="'Pending Deactivation'"></fw-chip>
          <fw-chip color="orange" *ngIf="row.deployment_status==='scheduled'" [title]="'Scheduled'"></fw-chip>
          <fw-chip color="slate" *ngIf="row.deployment_status==='undeployed'" [title]="'Undeployed'"></fw-chip>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="notes">
        <mat-header-cell *matHeaderCellDef mat-sort-header [testId]="'table-header-notes'">
          <h4>Notes</h4>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <fw-tooltip [title]="row.notes" position="below" [maxWidth]="320" *ngIf="row.notes && row.notes.length>0">
            <fw-icon size="medium" color="primary">notes-pen</fw-icon>
          </fw-tooltip>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header [testId]="'table-header-id'">
          <h4></h4>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="no-wrap align-right">
          <fw-button leftIcon="open" (click)="openTenant($event, row)" variant="ghost" [disabled]="row.deployment_status!=='deployed'">Launch</fw-button>
          &nbsp;
          <fw-icon-button icon="menu-vertical" (click)="$event.stopPropagation()" [cdkMenuTriggerFor]="linksMenu"></fw-icon-button>
          <ng-template #linksMenu>
            <fw-menu-container shadow="large">
              <fw-menu>
                <fw-menu-item *ngFor="let link of row.tenant_links; index as i"
                  [testId]="'tenant_link'+i"
                  [title]="link.name"
                  [href]="link.url"
                  target="_blank">
                </fw-menu-item>
              </fw-menu>
            </fw-menu-container>
          </ng-template>
        </mat-cell>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns; index as i"
        [ngClass]='!(selectedRow) || row.id === selectedRow.id ? "selected":""'
        [testId]="'table-row-'+i"
        (click)="rowSelected(row)">
      </tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell empty-pane" [attr.colspan]="displayedColumns.length">
          <fw-layout-context *ngIf="!orgTenantsQuery || !orgTenantsQuery.isFetching() || !orgTenantsQuery.isLoading()"
            icon="settings-gear-square"
            iconColor="secondary"
            title="No tenants found"
            description="Looks like there are not any tenants for that organization.">
          </fw-layout-context>
        </td>
      </tr>
    </table>

  </div>
</div>
